import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Sidebar from "../../scenes/global/Sidebar";
import Topbar from "../../scenes/global/Topbar";
import React, { useState } from "react";
import axios from "axios";
import { addDoc, collection, setDoc, doc } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import { MenuItem } from "react-pro-sidebar";

const ReleaseForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isSidebar, setIsSidebar] = useState(true);
  const { currentUser } = useAuth();
  const [form, setForm] = useState({
    title: "",
    type: "",
    desc: "",
  });
  const [data, setData] = useState({});

  const [error, setError] = useState({});

  function createUnixTS() {
    const date = new Date();
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  const handleInput = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    console.log(id, value);
    if (error[id]) {
      setError({ ...error, [id]: "" });
    }
    setForm({ ...form, [id]: value });
  };

  const getData = () => {};

  const validateForm = () => {
    const errors = {};
    if (form.title === "") {
      errors.title = "Title is required";
    }
    if (form.desc === "") {
      errors.desc = "Description is required";
    }
    // if (form.comments === "") {
    //   errors.comments = "Comments are required";
    // }
    setError(errors);
    return errors;
  };

  
  function createUnixTS() {
    const date = new Date();
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  const handleAdd = async (e) => {
    e.preventDefault();
    const valid = validateForm();
    console.log(valid);
    if (Object.keys(valid).length !== 0) {
      return;
    }
    // Main Firebase calls
    const res = addDoc(collection(db, "RELEASE"), {
        title: form.title,
        type: form.type,
        desc: form.desc,
        date: new Date().toLocaleDateString(),
        timestamp: createUnixTS()
    }).then((docRef) => {
        toast.success("Data Added",  {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
        toast.error("Error adding user");
      })
      .finally(() => {
        setForm(initialValues)
      })
    console.log(res)
  };

  const initialValues = {
    title: "",
    type: "",
    desc: ""
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <div>
          <Box m="20px">
            <Header
              title="Dev Release"
              subtitle="Add the latest release form."
            />

            <Formik
              onSubmit={handleAdd}
              initialValues={initialValues}
              // validationSchema={checkoutSchema}
            >
              {({
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleAdd}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Release Title"
                      onBlur={handleBlur}
                      onChange={handleInput}
                      value={form.title}
                      name="title"
                      error={error?.title}
                      helperText={touched.title && errors.title}
                      sx={{ gridColumn: "span 3" }}
                    />
                    <select
                      name="type"
                      id="type"
                      onChange={handleInput}
                      placeholder="Select type"
                      className="form-input2"
                      value={form.type}
                    >
                      <option className="pro-menu-item" disabled value="">Select Type</option>
                      <option className="pro-menu-item" value="Bug">Bug</option>
                      <option className="pro-menu-item" value="Feature">Feature</option>
                    </select>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Release Description"
                      onBlur={handleBlur}
                      onChange={handleInput}
                      value={form.desc}
                      name="desc"
                      error={!!touched.desc && !!errors.desc}
                      helperText={touched.desc && errors.desc}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="start" mt="20px">
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      // sx={{ width: "320px" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          {console.log(data)}
          {/* {Object.keys(data).length != 0 && (
            <Box m="20px">
              <Header title="Added Twitter Handle Details" subtitle="" />
              <p>
                Name: <b className="profile_details">{data?.name}</b>
              </p>
              <p>
                Username: <b className="profile_details">{data?.username}</b>
              </p>
              <p>
                Description:{" "}
                <b className="profile_details">{data?.description}</b>
              </p>
              <p>
                Followers:{" "}
                <b className="profile_details">
                  {data?.public_metrics.followers_count}
                </b>
              </p>
            </Box>
          )} */}
        </div>
      </main>
    </div>
  );
};

// const checkoutSchema = yup.object().shape({
//   fname: yup.string().required("required"),
//   lname: yup.string().required("required"),
//   email: yup.string().required("required"),
//   role: yup.string().required("required"),
// });

export default ReleaseForm;
