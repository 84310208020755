import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  startAfter,
  limit,
  orderBy,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import Avatar from "@mui/material/Avatar";
import "../scenes.css";
import LoadingGif from "../../assets/loading.gif";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import BasicDateRangePicker from "../unix";
import LinearProgress from "@mui/material/LinearProgress";
import { store } from "../../store/store";
import { toast } from "react-toastify";
import { Modal } from "../../components/Modal/Modal";
import { setLocalStorage } from "../../helpers/localStorage";

const Handles = () => {
  const timestamp_items = store.useState(
    (s) => s.followingsFilter_timestamp_items
  );
  const timestamp_enabled = store.useState(
    (s) => s.followingsFilter_timestamp_enabled
  );
  const created_at_items = store.useState(
    (s) => s.followingsFilter_created_at_items
  );
  const created_at_enabled = store.useState(
    (s) => s.followingsFilter_created_at_enabled
  );
  const global_items = store.useState((s) => s.followingsFilter_global_items);
  const timestamp_clear = store.useState(
    (s) => s.followingsFilter_timestamp_clear
  );
  const created_at_clear = store.useState(
    (s) => s.followingsFilter_created_at_clear
  );
  const upperTimestampLimit = store.useState(
    (s) => s.upperTimestampLimit_handles
  );
  const lowerTimestampLimit = store.useState(
    (s) => s.lowerTimestampLimit_handles
  );
  const rteamIds = store.useState((s) => s.rteamIds);
  const handlesColumns = store.useState((s) => s.handlesColumns);
  // split the rteamIds into batches of 10
  const rteamIdsBatches = [];
  for (let i = 0; i < rteamIds.length; i += 10) {
    rteamIdsBatches.push(rteamIds.slice(i, i + 10));
  }
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [following, setFollowing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [lastVisible, setLastVisible] = useState("");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [toastShown, setToastShown] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const data = [];
  const [fields, setFields] = useState({
    company: "",
    comments: "",
  });
  const [filterModel, setFilterModel] = useState(
    JSON.parse(localStorage.getItem("filterHandles")) || { items: [] }
  );
  const [localList, setLocalList] = useState([]);

  useEffect(() => {
    const newList = JSON.parse(localStorage.getItem("handleList"));
    setLocalList(newList);
  }, [localStorage.getItem("handleList")]);

  function containsObject(obj, list) {
    return list.find(
      (elem) => JSON.stringify(elem.items) === JSON.stringify(obj.items)
    );
    // return false;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value,
    });
  };

  useEffect(() => {
    setFirstLoad(true);
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [!loading, upperTimestampLimit, lowerTimestampLimit, rteamIds]);

  function createUnixTS(dateStr) {
    const date = new Date(dateStr);
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  function unixToReadable(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    if (month < 10) {
      month = "0" + month.toString();
    }
    var date = a.getDate();
    if (date < 10) {
      date = "0" + date.toString();
    }
    var hour = a.getHours();
    if (hour < 10) {
      hour = "0" + hour.toString();
    }
    var min = a.getMinutes();
    if (min < 10) {
      min = "0" + min.toString();
    }
    var sec = a.getSeconds();
    if (sec < 10) {
      sec = "0" + sec.toString();
    }
    var time =
      year + "/" + month + "/" + date + ", " + hour + ":" + min + ":" + sec;
    return time;
  }

  const gettData = async () => {
    setLoading(true);
    const first = query(
      collection(db, "USERS"),
      orderBy("timestamp", "desc"),
      where("timestamp", ">", lowerTimestampLimit),
      where("timestamp", "<", upperTimestampLimit),
      where("added_by", "in", rteamIds),
      // startAfter(lastVisible),
      limit(500)
    );

    const d = await getDocs(first);

    const last = d.docs[d.docs.length - 1];

    setLastVisible(last);

    const follow = d.docs.map((doc) => ({
      data: doc.data(),
      id: doc.id,
    }));
    console.log(follow, "iidd");

    for (let i = 0; i < follow.length; i++) {
      const data_object = {};
      data_object.pfp = { profile_image_url: "", username: "" };
      data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
      data_object.pfp.username = follow[i].id;
      data_object.username = follow[i].id;
      data_object.name = follow[i].data.name;
      data_object.id = follow[i].data.id;
      data_object.company = follow[i].data.company;
      data_object.comments = follow[i].data.comments;
      data_object.followers_count =
        follow[i].data.public_metrics.followers_count;
      data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
      data_object.description = follow[i].data.description;
      data_object.created_at = createUnixTS(follow[i].data.created_at);
      data_object.created_at_utc = unixToReadable(
        createUnixTS(follow[i].data.created_at)
      );
      data_object.timestamp = follow[i].data.timestamp;
      data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
      data.push(data_object);
    }

    if (follow.length === 0) {
      setLoading(true);
      setFollowing(following.concat(data));
      setIsLoading(false);
      setLoading(false);
    } else {
      setFollowing(following.concat(data));
      setIsLoading(false);
      setLoading(false);
    }
  };

  async function getData() {
    setLoading(true);

    if (lastVisible === "") {
      console.log("Inside if");
      setToastShown(false);
      const first = query(
        collection(db, "USERS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        where("added_by", "in", rteamIds),
        limit(500)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];

      setLastVisible(last);

      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));
      console.log(follow, "with id");

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "" };
        data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
        data_object.pfp.username = follow[i].id;
        data_object.username = follow[i].id;
        data_object.name = follow[i].data.name;
        data_object.id = follow[i].data.id;
        data_object.company = follow[i].data.company;
        data_object.comments = follow[i].data.comments;
        data_object.followers_count =
          follow[i].data.public_metrics.followers_count;
        data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
        data_object.description = follow[i].data.description;
        data_object.created_at = createUnixTS(follow[i].data.created_at);
        data_object.created_at_utc = unixToReadable(
          createUnixTS(follow[i].data.created_at)
        );
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    } else {
      console.log("Inside else");

      if (!lastVisible && !toastShown) {
        toast.success("Data loaded successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToastShown(true);
        return;
      }

      const first = query(
        collection(db, "USERS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        where("added_by", "in", rteamIds),
        startAfter(lastVisible),
        limit(500)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];

      setLastVisible(last);

      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));
      console.log(follow, "iidd");

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "" };
        data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
        data_object.pfp.username = follow[i].id;
        data_object.username = follow[i].id;
        data_object.name = follow[i].data.name;
        data_object.id = follow[i].data.id;
        data_object.company = follow[i].data.company;
        data_object.comments = follow[i].data.comments;
        data_object.followers_count =
          follow[i].data.public_metrics.followers_count;
        data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
        data_object.description = follow[i].data.description;
        data_object.created_at = createUnixTS(follow[i].data.created_at);
        data_object.created_at_utc = unixToReadable(
          createUnixTS(follow[i].data.created_at)
        );
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    }
  }

  const deleteHandles = async (handle) => {
    const response = await deleteDoc(doc(db, "USERS", handle));
    if (response === undefined) {
      toast.success(`Deleted ${handle} from database`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setFollowing(following.filter((item) => item.username !== handle));
      setModal(false);
      return;
    }
    toast.error(`Error deleting ${handle} from database`);
  };

  const updateHandles = async (e) => {
    e.preventDefault();
    if (fields?.comments || fields?.company) {
      try {
        console.log(modalContent);
        const res = await updateDoc(doc(db, "USERS", modalContent?.username), {
          company:
            fields?.company !== "" ? fields?.company : modalContent?.company,
          comments:
            fields?.comments !== "" ? fields?.comments : modalContent?.comments,
        });
        setModal(false);
        toast.success(
          `Updated data for ${modalContent?.username} successfully!`
        );
        await gettData();
      } catch (err) {
        setModal(false);
        toast.error(`Failed to update data for ${modalContent?.username}!`);
      }
    }
  };

  function getFollowings() {
    const followingCollectionRef = collection(db, "USERS");

    getDocs(followingCollectionRef)
      .then((response) => {
        const follow = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        // console.log(follow);
        for (let i = 0; i < follow.length; i++) {
          const data_object = {};
          data_object.pfp = { profile_image_url: "", username: "" };
          data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
          data_object.pfp.username = follow[i].id;
          data_object.username = follow[i].id;
          data_object.name = follow[i].data.name;
          data_object.id = follow[i].data.id;
          data_object.company = follow[i].data.company;
          data_object.comments = follow[i].data.comments;
          data_object.followers_count =
            follow[i].data.public_metrics.followers_count;
          data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
          data_object.description = follow[i].data.description;
          data_object.created_at = createUnixTS(follow[i].data.created_at);
          data_object.created_at_utc = unixToReadable(
            createUnixTS(follow[i].data.created_at)
          );
          data_object.timestamp = follow[i].data.timestamp;
          data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
          data.push(data_object);
        }
        setIsLoading(false);
        setFollowing(data);
      })
      .catch((error) => console.log(error.message));
  }

  const columns = [
    {
      field: "pfp",
      headerName: "PFP",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/${params.value.username}`}
            >
              <Avatar src={params.value.profile_image_url} />
            </a>
          </>
        );
      },
    },
    {
      field: "username",
      headerName: "Username",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },
    {
      field: "id",
      headerName: "Twitter ID",
      headerAlign: "left",
      align: "left",
      flex: 0.3,
    },
    {
      field: "followers_count",
      headerName: "Followers",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "tweet_count",
      headerName: "Tweets",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 4,
    },
    {
      field: "company",
      headerName: "Company",
      flex: 0.5,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 0.5,
    },
    {
      field: "created_at_utc",
      headerName: "Creation (yyyy/mm/dd)",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "timestamp_utc",
      headerName: "Timestamp (yyyy/mm/dd)",
      flex: 0.7,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "created_at",
      headerName: "Creation (UNIX)",
      flex: 0.3,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "timestamp",
      headerName: "Timestamp (UNIX)",
      flex: 0.3,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
  ];
  const timestampFilterModelItems = () => {
    return timestamp_enabled ? timestamp_items : [];
  };

  const created_atFilterModelItems = () => {
    return created_at_enabled ? created_at_items : [];
  };
  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="TWITTER HANDLES"
              subtitle="List of all Twitter Handles for tracking their activity."
            />
            <img className="loading_image" src={LoadingGif} alt="loading" />
            <p className="loading_text">
              Please be Patient! Fetching all data!
            </p>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px" className="container_flex">
            <Header
              className="header_flex"
              title="TWITTER HANDLES"
              subtitle="List of all Twitter Handles for tracking their activity."
            />
            <BasicDateRangePicker
              followingCallback={setFollowing}
              lastVisibleCallback={setLastVisible}
              unix={"timestamp"}
              className="date_flex"
              is_handle={true}
            />
            <BasicDateRangePicker unix={"created_at"} className="date_flex" />
          </Box>
          <Box m="20px" className="container_flex" gap={2}>
            {localList?.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Button
                  onClick={() => {
                    localStorage.setItem("filterHandles", JSON.stringify(item));
                    setFilterModel(item);
                  }}
                  variant="outlined"
                  color="info"
                >
                  Config {index + 1}
                </Button>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const newList = localList.filter(
                      (listt) => JSON.stringify(listt) !== JSON.stringify(item)
                    );
                    localStorage.setItem("handleList", JSON.stringify(newList));
                    setLocalList(newList);
                  }}
                />
              </div>
            ))}
          </Box>
          <Box
            m="20px"
            className="container_flex"
            justifyContent={"space-between"}
          >
            <Button
              onClick={() => {
                const currentConfig = JSON.parse(
                  localStorage.getItem("filterHandles")
                );
                if (currentConfig?.items?.length > 0) {
                  const list =
                    localStorage.getItem("handleList") !== null
                      ? JSON.parse(localStorage.getItem("handleList"))
                      : [];
                  if (!containsObject(currentConfig, list)) {
                    console.log(list, containsObject(currentConfig, list));
                    list.push(currentConfig);
                    const newList = JSON.stringify(list);
                    // console.log(currentConfig, newList)
                    localStorage.setItem("handleList", newList);
                    setLocalList(list);
                  } else {
                    toast.error("Config Already Exists")
                  }
                }
              }}
              variant="contained"
              color="secondary"
            >
              Save Filter Config
            </Button>
            <Button
              onClick={() => {
                localStorage.removeItem("filterHandles");
                setFilterModel({ items: [] });
              }}
              variant="contained"
              color="secondary"
            >
              Clear All Filters
            </Button>
          </Box>
          <Box m="20px">
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGridPro
                autoHeight
                rows={following}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                onRowClick={(params) => {
                  setModalContent(params.row);
                  setFields({
                    comments: "",
                    company: "",
                  });
                  console.log(params);
                  setModal(true);
                }}
                pagination
                rowsPerPageOptions={[50, 100, 300, 500]}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {
                  localStorage.setItem(
                    "filterHandles",
                    JSON.stringify(newFilterModel)
                  );
                  console.log(newFilterModel);
                  setFilterModel(newFilterModel);
                }}
                //filterMode={"client"}
                // onFilterModelChange={(model) => {
                //   store.update((state) => {
                //     // state.followingsFilter_timestamp_enabled = false;
                //     //   state.followingsFilter_created_at_enabled = false;
                //     if (!timestamp_clear || !created_at_clear) {
                //       state.followingsFilter_timestamp_enabled = false;
                //       state.followingsFilter_created_at_enabled = false;
                //       state.followingsFilter_timestamp_items = [];
                //       state.followingsFilter_created_at_items = [];
                //       state.followingsFilter_timestamp_clear = true;
                //       state.followingsFilter_created_at_clear = true;
                //     } else state.followingsFilter_global_items = model.items;
                //   });
                // }}
                // filterModel={{
                //   items: [
                //     ...timestampFilterModelItems(),
                //     ...created_atFilterModelItems(),
                //     ...global_items,
                //   ],
                // }}
                onColumnVisibilityModelChange={(model) => {
                  store.update((state) => {
                    state.handlesColumns = model;
                    setLocalStorage("handlesColumns", model);
                  });
                }}
                columnVisibilityModel={handlesColumns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </main>
        <Modal isVisible={modal}>
          <div className="closeIcon">
            <CloseIcon
              onClick={() => {
                setModal(false);
                setConfirmDelete(false);
              }}
            />
          </div>
          <div
            className="modalContent"
            style={{
              display: "flex",
              gap: 6,
              alignItems: "start",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://twitter.com/${modalContent?.username}`}
              >
                <img
                  className="profile_img"
                  src={modalContent?.pfp?.profile_image_url}
                  alt=""
                />
              </a>
              {/* Name */}
              <div className="label">
                <span>Name:</span> {modalContent?.name}
              </div>
              <div className="label">
                <span>Username:</span> {modalContent?.username}
              </div>
              <div className="label">
                <span>Description:</span> {modalContent?.description}
              </div>
              <div className="label">
                <span>Created at:</span> {modalContent?.created_at_utc}
              </div>
              <div className="label">
                <span>Folllowers count:</span> {modalContent?.followers_count}
              </div>
              <div className="label">
                <span>Company:</span> {modalContent?.company}
              </div>
              <div className="label">
                <span>Comments:</span> {modalContent?.comments}
              </div>
              <div className="label">
                <span>Tweet count:</span> {modalContent?.tweet_count}
              </div>
              <div className="label">
                <span>Id:</span> {modalContent?.id}
              </div>
            </div>
            <div
              style={{
                height: "100%",
                width: 4,
                backgroundColor: "white",
              }}
            />
            <form
              onSubmit={updateHandles}
              style={{
                padding: "20px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                width: "50%",
              }}
            >
              <h2>Update</h2>
              <div className="form-row2">
                <div className="form-group2">
                  <label htmlFor="name_tg">Edit Company</label>
                  <input
                    onChange={handleChange}
                    type="text"
                    className="form-input"
                    placeholder="Enter Company"
                    id="company"
                    style={{
                      width: "100%",
                    }}
                    name="company"
                    value={fields?.company}
                  />
                </div>
                <div className="form-group2">
                  <label htmlFor="name_tg">Edit Comment</label>
                  <input
                    onChange={handleChange}
                    type="text"
                    className="form-input"
                    placeholder="Enter Comment"
                    id="comments"
                    style={{
                      width: "100%",
                    }}
                    name="comments"
                    value={fields?.comments}
                  />
                </div>
              </div>
              <div className="form-row">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "12px 20px",
                    backgroundColor: "#00bfa5",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    borderRadius: "5px",
                  }}
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
          {/* Delete Button */}
          <div className="deleteButton">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setConfirmDelete(!confirmDelete);
              }}
            >
              {confirmDelete ? "Cancel Deletion" : "Delete"}
            </Button>
          </div>
          {confirmDelete && (
            <div className="deleteButton">
              <p className="error_msg">
                This data will be deleted from database for everyone, please
                confirm your deletion!
              </p>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  deleteHandles(modalContent?.username);
                  // setModal(false);
                }}
              >
                Confirm Delete
              </Button>
            </div>
          )}
        </Modal>
      </div>
    );
  }
};

export default Handles;
