import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../utils/init-firebase";
import "./profile.css";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

let data = {};

const Profile = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isSidebar, setIsSidebar] = useState(true);
  const { currentUser } = useAuth();
  const [team, setTeam] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Form Variables
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  // Final Form data to update
  const [form, setForm] = useState({});

  data = {};

  const handleInput = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    setForm({ ...form, [id]: value });
    console.log(form);
  };

  const handleAdd = async (e) => {
    // e.preventDefault();
    try {
      await setDoc(doc(db, "RTEAM", currentUser.uid), {
        ...form,
        timeStamp: serverTimestamp(),
      });
      getData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    const teamCollectionRef = collection(db, "RTEAM");
    getDocs(teamCollectionRef)
      .then((response) => {
        const tm = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        for (let i = 0; i < tm.length; i++) {
          if (tm[i].id === currentUser.uid) {
            data = tm[i].data;
          }
        }
        console.log(data);
        setFname(data.fname);
        setLname(data.lname);
        setEmail(data.email);
        setRole(data.role);
        setIsLoading(false);
      })
      .catch((error) => console.log(error.message));
  }

  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const initialValues = {
    fname: "fname",
    lname: "lname",
    email: "email",
    role: "role",
  };
  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        {!isLoading && (
          <div>
            <Box m="20px">
              <Header title="MY PROFILE" subtitle="My profile details." />
              <p>
                First Name: <b className="profile_details">{fname}</b>
              </p>
              <p>
                Last Name: <b className="profile_details">{lname}</b>
              </p>
              <p>
                Work Email: <b className="profile_details">{email}</b>
              </p>
              <p>
                Role/Designation: <b className="profile_details">{role}</b>
              </p>
            </Box>

            <Box m="20px">
              <Header
                title="EDIT PROFILE"
                subtitle="Edit your profile details."
              />

              <Formik
                onSubmit={handleAdd}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
              >
                {({
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": {
                          gridColumn: isNonMobile ? undefined : "span 4",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="First Name"
                        onBlur={handleBlur}
                        onChange={handleInput}
                        // value={fname}
                        name="fname"
                        error={!!touched.fname && !!errors.fname}
                        helperText={touched.fname && errors.fname}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Last Name"
                        onBlur={handleBlur}
                        onChange={handleInput}
                        // value={lname}
                        name="lname"
                        error={!!touched.lname && !!errors.lname}
                        helperText={touched.lname && errors.lname}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Email"
                        onBlur={handleBlur}
                        onChange={handleInput}
                        // value="{email}"
                        name="email"
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Role/Designation"
                        onBlur={handleBlur}
                        onChange={handleInput}
                        // value={role}
                        name="role"
                        error={!!touched.role && !!errors.role}
                        helperText={touched.role && errors.role}
                        sx={{ gridColumn: "span 2" }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="end" mt="20px">
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                      >
                        Save Changes
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </div>
        )}
      </main>
    </div>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  fname: yup.string().required("required"),
  lname: yup.string().required("required"),
  email: yup.string().required("required"),
  role: yup.string().required("required"),
});

export default Profile;
