import { Box } from "@mui/system";
import React from "react";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import LoadingGif from "../../assets/loading.gif";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PendingIcon from "@mui/icons-material/Pending";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { useEffect } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import BasicDateRangePicker from "../unix";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { store } from "../../store/store";
import { setLocalStorage } from "../../helpers/localStorage";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "../../components/Modal/Modal";
import firebase from "firebase/compat/app";
import { useAuth } from "../../contexts/AuthContext";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Label, AttachFile } from "@mui/icons-material";
import { toast } from "react-toastify";

const naList = ["N/A", "NA", "n/a", "na"];

const CategoryList = [
  "N/A",
  "GameFi",
  "Metaverse",
  "DeFi",
  "Tooling/Infrastructure",
  "NFT",
  "Wallet",
  "Layer 0",
  "Layer 1",
  "Layer 2",
  "DAO",
  "Launchpad",
  "Marketplace",
  "DEX",
  "CEX",
];

const Monitoring = () => {
  const timestamp_items = store.useState(
    (s) => s.followingsFilter_timestamp_items
  );
  const timestamp_enabled = store.useState(
    (s) => s.followingsFilter_timestamp_enabled
  );
  const created_at_items = store.useState(
    (s) => s.followingsFilter_created_at_items
  );
  const created_at_enabled = store.useState(
    (s) => s.followingsFilter_created_at_enabled
  );
  const global_items = store.useState((s) => s.followingsFilter_global_items);
  const timestamp_clear = store.useState(
    (s) => s.followingsFilter_timestamp_clear
  );
  const created_at_clear = store.useState(
    (s) => s.followingsFilter_created_at_clear
  );

  const monitioringColumns = store.useState((s) => s.monitioringColumns);

  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [monitoring, setMonitoring] = useState([]);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [Id, setId] = useState("");
  const [tgData, setTgData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(dayjs(""));
  const [agenda, setAgenda] = useState("");
  const [notes, setNotes] = useState("");
  const [calls, setCalls] = useState([]);
  const [callExecuted, setCallExecuted] = useState("NO");
  const [threadData, setThreadData] = useState([]);
  const [detailMonitoring, setDetailMonitoring] = useState([]);
  const [filterModel, setFilterModel] = useState(
    JSON.parse(localStorage.getItem("filterMonitoring")) || { items: [] }
  );
  const [localList, setLocalList] = useState([]);

  useEffect(() => {
    const newList = JSON.parse(localStorage.getItem("monList"));
    setLocalList(newList);
  }, [localStorage.getItem("monList")]);

  const { currentUser } = useAuth();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function containsObject(obj, list) {
    return list.find(
      (elem) => JSON.stringify(elem.items) === JSON.stringify(obj.items)
    );
    // return false;
  }

  const getData = async () => {
    const monitoringData = await (
      await getDocs(query(collection(db, "MONITORING"), orderBy("id", "desc")))
    ).docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setMonitoring(monitoringData);
    setIsLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    getData();

    return () => {
      setLoading(false);
    };
  }, []);

  const handleSaveTG = async (e) => {
    e.preventDefault();
    console.log(detailMonitoring, "detailMonitoring", Id);
    const res = await setDoc(doc(db, "MONITORING", `${Id}`), {
      ...detailMonitoring,
    });
    console.log(res, "res");
    getDetailMonitoring(Id);
  };

  const handleAddMessage = async (e) => {
    e.preventDefault();
    const res = await addDoc(collection(db, "MONITORING", `${Id}`, "TG"), {
      message: message,
      user: currentUser?.email.split("@")[0],
      timestamp: serverTimestamp(),
    });
    setMessage("");
    getTg(Id);
  };

  const handleAddCall = async (e) => {
    e.preventDefault();
    const res = await addDoc(collection(db, "MONITORING", `${Id}`, "CALLS"), {
      agenda: agenda,
      user: currentUser?.email.split("@")[0],
      notes: notes,
      time: date.toDate(),
      callExecuted: callExecuted,
      timestamp: serverTimestamp(),
    });
    setAgenda("");
    setNotes("");
    setDate(dayjs(""));
    setCallExecuted("NO");
    getCalls(Id);
  };

  const deleteCall = async (id) => {
    const res = await deleteDoc(
      doc(db, "MONITORING", `${Id}`, "CALLS", `${id}`)
    );
    getCalls(Id);
  };

  const deleteMonitoring = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteDoc(doc(db, "MONITORING", `${Id}`));
        getData();
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        setModal(false);
      }
    });
  };

  const deleteMessage = async (id) => {
    const res = await deleteDoc(doc(db, "MONITORING", `${Id}`, "TG", `${id}`));
    getTg(Id);
  };

  const handleMonitoringChange = (e) => {
    setDetailMonitoring({
      ...detailMonitoring,
      [e.target.name]: e.target.value,
    });
  };

  const handleCallExecutedChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(
      doc(db, "MONITORING", `${Id}`, "CALLS", `${id}`),
      {
        callExecuted: value,
      }
    );
    getCalls(Id);
  };

  const handleVotingChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      voting: value,
    });
    getData();
  };

  const handleCategoryChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      category: value,
    });
    getData();
  };

  const handleCallChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      call: value,
    });
    getData();
  };

  const handleChannelChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      channel: value,
    });
    getData();
  };

  const handleInvestedChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      invested: value,
    });
    getData();
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setId(params?.id);
              getTg(params?.id);
              getCalls(params?.id);
              getThread(params?.id);
              getDetailMonitoring(params?.id);
              setModal(true);
            }}
            className="name-column--cell internal-cell"
            style={{
              width: "100% !important",
              height: "100% !important",
            }}
          >
            {params?.value}
          </div>
        );
      },
    },
    {
      field: "twitter",
      headerName: "Twitter",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            {naList.includes(params.value) ? (
              <div>{params?.value}</div>
            ) : (
              <a
                className="twitter_url"
                target="_blank"
                rel="noopener noreferrer"
                href={`${params.value}`}
              >
                {params?.value}
              </a>
            )}
          </>
        );
      },
    },
    {
      field: "website",
      headerName: "Website",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            {naList.includes(params.value) ? (
              <div>{params?.value}</div>
            ) : (
              <a
                className="twitter_url"
                target="_blank"
                rel="noopener noreferrer"
                href={`${params.value}`}
              >
                {params?.value}
              </a>
            )}
          </>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      headerAlign: "center",
      flex: 0.9,
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            label="Category"
            onChange={(e) => handleCategoryChange(e, params?.id)}
          >
            {CategoryList.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.4,
    },
    {
      field: "author",
      headerName: "Author",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.5,
    },
    {
      field: "message_url",
      headerName: "GoTo Discord",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.value}`}
            >
              <Button variant="contained">Discord</Button>
            </a>
          </>
        );
      },
    },
    {
      field: "voting",
      headerName: "Voting",
      headerAlign: "center",
      flex: 0.4,
      alignItems: "center",
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Voting"
            onChange={(e) => handleVotingChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "call",
      headerName: "Call",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Call"
            onChange={(e) => handleCallChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "channel",
      headerName: "Channel",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Channel"
            onChange={(e) => handleChannelChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "invested",
      headerName: "Invested",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Invested"
            onChange={(e) => handleInvestedChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
  ];

  const getDetailMonitoring = async (id) => {
    const res = await getDoc(doc(db, "MONITORING", `${id}`)).then((doc) => {
      if (doc.exists()) {
        return doc.data();
      } else {
        console.log("No such document!");
      }
    });
    setDetailMonitoring(res);
  };

  const getTg = async (id) => {
    const res = await (
      await getDocs(collection(db, "MONITORING", `${id}`, "TG"))
    ).docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setTgData(res);
  };

  const getThread = async (id) => {
    const res = await (
      await getDocs(collection(db, "MONITORING", `${id}`, "THREAD"))
    ).docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log(res, "RES");
    setThreadData(res);
  };

  const getCalls = async (id) => {
    const res = await (
      await getDocs(
        query(
          collection(db, "MONITORING", `${id}`, "CALLS"),
          orderBy("time", "asc")
        )
      )
    ).docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setCalls(res);
  };

  const timestampFilterModelItems = () => {
    return timestamp_enabled ? timestamp_items : [];
  };

  const created_atFilterModelItems = () => {
    if (created_at_enabled) {
    }
    return created_at_enabled ? created_at_items : [];
  };

  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="Monitoring"
              subtitle="Tracking project leads and their status from WWV Research #monitoring discord channel."
            />
          </Box>

          <img className="loading_image" src={LoadingGif} alt="loading" />
          <p className="loading_text">Please be Patient! Fetching all data!</p>
        </main>
      </div>
    );
  } else
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box
            m="20px"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
            className="container_flex"
          >
            <Header
              className="header_flex"
              title="Monitoring"
              subtitle="Tracking project leads and their status from WWV Research #monitoring discord channel."
            />
            <Button
              variant="contained"
              style={{
                height: "40px",
              }}
              color="secondary"
              onClick={() => {
                getData();
              }}
            >
              Refresh Data
            </Button>
          </Box>
          <Box m="20px" className="container_flex" gap={2}>
            {localList?.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Button
                  onClick={() => {
                    localStorage.setItem(
                      "filterMonitoring",
                      JSON.stringify(item)
                    );
                    setFilterModel(item);
                  }}
                  variant="outlined"
                  color="info"
                >
                  Config {index + 1}
                </Button>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const newList = localList.filter(
                      (listt) => JSON.stringify(listt) !== JSON.stringify(item)
                    );
                    localStorage.setItem("monList", JSON.stringify(newList));
                    setLocalList(newList);
                  }}
                />
              </div>
            ))}
          </Box>
          <Box
            m="20px"
            className="container_flex"
            justifyContent={"space-between"}
          >
            <Button
              onClick={() => {
                const currentConfig = JSON.parse(
                  localStorage.getItem("filterMonitoring")
                );
                if (currentConfig?.items?.length > 0) {
                  const list =
                    localStorage.getItem("monList") !== null
                      ? JSON.parse(localStorage.getItem("monList"))
                      : [];
                  if (!containsObject(currentConfig, list)) {
                    console.log(list, containsObject(currentConfig, list));
                    list.push(currentConfig);
                    const newList = JSON.stringify(list);
                    // console.log(currentConfig, newList)
                    localStorage.setItem("monList", newList);
                    setLocalList(list);
                  } else {
                    toast.error("Config Already Exists");
                  }
                }
              }}
              variant="contained"
              color="secondary"
            >
              Save Filter Config
            </Button>
            <Button
              onClick={() => {
                localStorage.removeItem("filterMonitoring");
                setFilterModel({ items: [] });
              }}
              variant="contained"
              color="secondary"
            >
              Clear All Filters
            </Button>
          </Box>
          <Box m="20px">
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGridPro
                autoHeight
                rows={monitoring}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                // on row click set the row details in the state in the setModalContent
                // loading={loading}
                // onRowsScrollEnd={handleOnRowsScrollEnd}
                // hideFooterPagination
                pagination
                rowsPerPageOptions={[50, 100, 300, 500]}
                //filterMode={"client"}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {
                  localStorage.setItem(
                    "filterMonitoring",
                    JSON.stringify(newFilterModel)
                  );
                  console.log(newFilterModel);
                  setFilterModel(newFilterModel);
                }}
                // onFilterModelChange={(model) => {
                //   store.update((state) => {
                //     console.log(model, state, "MODEL");
                //     // state.followingsFilter_timestamp_enabled = false;
                //     //   state.followingsFilter_created_at_enabled = false;
                //     if (!timestamp_clear || !created_at_clear) {
                //       state.followingsFilter_timestamp_enabled = false;
                //       state.followingsFilter_created_at_enabled = false;
                //       state.followingsFilter_timestamp_items = [];
                //       state.followingsFilter_created_at_items = [];
                //       state.followingsFilter_timestamp_clear = true;
                //       state.followingsFilter_created_at_clear = true;
                //     } else state.followingsFilter_global_items = model.items;
                //   });
                // }}
                // filterModel={{
                //   items: [
                //     ...timestampFilterModelItems(),
                //     ...created_atFilterModelItems(),
                //     ...global_items,
                //   ],
                // }}
                onColumnVisibilityModelChange={(model) => {
                  store.update((state) => {
                    state.monitioringColumns = model;
                    setLocalStorage("monitioringColumns", model);
                  });
                }}
                columnVisibilityModel={monitioringColumns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </main>
        <Modal isVisible={modal}>
          <div className="closeIcon">
            <CloseIcon
              onClick={() => {
                setModal(false);
              }}
            />
          </div>
          <div className="modalContent">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {["Votes", "Thread", "TG", "Calls"].map((item, index) => (
                    <Tab label={item} {...a11yProps(0)} />
                  ))}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Typography p={"10px"} variant="h4" component="h4">
                  Votes
                </Typography>
                <Box
                  style={{
                    overflowY: "scroll",
                    height: "300px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography variant="h4" component="h4">
                        YES (
                        {
                          detailMonitoring?.team_reactions?.filter(
                            (item) => item.vote === "YES"
                          )?.length
                        }
                        )
                      </Typography>
                      {detailMonitoring?.team_reactions
                        ?.filter((item) => item.vote === "YES")
                        ?.map((item, index) => (
                          <Typography variant="h6" component="h6">
                            <span className="title">Name: </span>
                            {item?.name}
                          </Typography>
                        ))}
                    </div>
                    <div>
                      <Typography variant="h4" component="h4">
                        No (
                        {
                          detailMonitoring?.team_reactions?.filter(
                            (item) => item.vote === "NO"
                          )?.length
                        }
                        )
                      </Typography>
                      {detailMonitoring?.team_reactions
                        ?.filter((item) => item.vote === "NO")
                        ?.map((item, index) => (
                          <Typography variant="h6" component="h6">
                            <span className="title">Name: </span>
                            {item?.name}
                          </Typography>
                        ))}
                    </div>
                    <Box></Box>
                  </div>
                  {/* Delete button */}
                </Box>
                <Typography p={"10px"} variant="h4" component="h4">
                  Delete project from Monitoring?
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    deleteMonitoring();
                    // setDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Typography p={"10px"} variant="h4" component="h4">
                  Threads
                </Typography>
                <Box
                  style={{
                    overflowY: "scroll",
                    height: "300px",
                  }}
                >
                  {threadData?.map((item, index) => (
                    <Box key={index} p={"10px"} borderBottom={1}>
                      <Typography variant="h4" component="h4">
                        <span className="title">{item?.author}</span>
                      </Typography>
                      <Typography variant="h4" component="h4">
                        {item?.content}
                      </Typography>
                      <Box
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item?.attachments?.map((item, index) => (
                          <a
                            href={item}
                            target="_blank"
                            rel="noreferrer"
                            key={index}
                          >
                            <AttachFile
                              style={{
                                fontSize: "30px",
                                marginRight: "10px",
                                color: "white",
                              }}
                            />
                          </a>
                        ))}
                      </Box>
                      <Box
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(item?.message_url, "_blank");
                          }}
                        >
                          Go to Discord
                        </Button>
                        <Typography variant="h4" component="h4">
                          <span className="title">
                            {/* convert firebase timestamp to normal date time in american format MM DD YYYY */}
                            {new Date(
                              item?.created_at?.seconds * 1000
                            ).toLocaleString("en-US")}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                {/* render Tg List of messages */}
                <Typography p={"10px"} variant="h4" component="h4">
                  TG Channel Details
                </Typography>
                {/* <Typography p={"10px"} variant="h4" component="h4">
                  Messages
                </Typography>
                <Box
                  style={{
                    overflowY: "scroll",
                    height: "300px",
                  }}
                >
                  {tgData?.map((item, index) => (
                    <div 
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderBottom: "1px solid #fff"
                    }}>
                      <Box>
                        <Typography variant="h4" component="h4">
                        <span className="title">User: </span>{item?.user}
                        </Typography>
                        <Typography variant="h4" component="h4">
                        <span className="title">Message: </span>{item?.message}
                        </Typography>
                      </Box>
                      <Button
                        varint="contained"
                        color="primary"
                        style={{
                          marginLeft: "20px",
                          padding: "16px 20px",
                          backgroundColor: colors.redAccent[300],
                        }}
                        onClick={() => deleteMessage(item?.id)}
                      >
                        DELETE
                      </Button>
                    </div>
                  ))}
                </Box> */}
                {/* Add Message */}
                <form
                  onSubmit={handleSaveTG}
                  style={{
                    padding: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="name_tg">Team Member Name</label>
                      <input
                        onChange={handleMonitoringChange}
                        type="text"
                        className="form-input"
                        placeholder="Enter Team Member Name"
                        id="name_tg"
                        name="name_tg"
                        value={detailMonitoring?.name_tg || ""}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="date_tg">Creation Date</label>
                      <input
                        onChange={handleMonitoringChange}
                        placeholder="Enter Creation Date"
                        type="date"
                        className="form-input"
                        id="date_tg"
                        name="date_tg"
                        value={detailMonitoring?.date_tg || ""}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="channel_tg">Channel Link</label>
                      <input
                        onChange={handleMonitoringChange}
                        type="url"
                        placeholder="Enter Channel Link"
                        className="form-input"
                        id="channel_tg"
                        name="channel_tg"
                        value={detailMonitoring?.channel_tg || ""}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="notes_tg">Additional Notes</label>
                      <input
                        onChange={handleMonitoringChange}
                        type="text"
                        className="form-input"
                        id="notes_tg"
                        name="notes_tg"
                        value={detailMonitoring?.notes_tg || ""}
                        placeholder="Enter Additional Notes"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="channelLink">Active Channel</label>
                      <select
                        id="active_channel_tg"
                        name="active_channel_tg"
                        onChange={handleMonitoringChange}
                        value={detailMonitoring?.active_channel_tg || ""}
                        className="form-input"
                        required
                      >
                        <option disabled value="">
                          Select Active Channel
                        </option>
                        <option value="YES">Yes</option>
                        <option value="NO">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        padding: "12px 20px",
                        backgroundColor: "#00bfa5",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                        borderRadius: "5px",
                      }}
                    >
                      Save Changes
                    </button>
                    {detailMonitoring?.channel_tg && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          window.open(detailMonitoring?.channel_tg, "_blank");
                        }}
                      >
                        Go to Channel
                      </Button>
                    )}
                  </div>
                </form>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Typography p={"10px"} variant="h4" component="h4">
                  Scheduled Calls
                </Typography>
                <Box
                  style={{
                    overflowY: "scroll",
                    height: "300px",
                  }}
                >
                  {calls?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        borderBottom: "1px solid #fff",
                      }}
                    >
                      <Box>
                        <Typography variant="h4" component="h4">
                          <span className="title">User: </span>
                          {item?.user}
                        </Typography>
                        <Typography variant="h4" component="h4">
                          <span className="title">Agenda: </span>
                          {item?.agenda}
                        </Typography>
                        {/* <Typography variant="h4" component="h4">
                          Time: {item?.time}
                        </Typography> */}
                        <Typography variant="h4" component="h4">
                          <span className="title">Notes: </span>
                          {item?.notes}
                        </Typography>
                        <Typography variant="h4" component="h4">
                          <span className="title">Call Scheduled at: </span>
                          {new Date(item?.time.seconds * 1000).toLocaleString(
                            "en-US"
                          )}
                        </Typography>
                        <Typography variant="h4" component="h4">
                          <span className="title">Call Executed: </span>
                        </Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={item?.callExecuted}
                          className={
                            item?.callExecuted === "YES"
                              ? "green"
                              : item?.callExecuted === "PENDING"
                              ? "yellow"
                              : "red"
                          }
                          label="Call Executed"
                          onChange={(e) =>
                            handleCallExecutedChange(e, item?.id)
                          }
                        >
                          <MenuItem value={"YES"}>YES</MenuItem>
                          <MenuItem value={"NO"}>NO</MenuItem>
                        </Select>
                      </Box>
                      <Button
                        varint="contained"
                        color="primary"
                        style={{
                          marginLeft: "20px",
                          padding: "16px 20px",
                          backgroundColor: colors.redAccent[300],
                        }}
                        onClick={() => deleteCall(item?.id)}
                      >
                        DELETE
                      </Button>
                    </div>
                  ))}
                </Box>
                {/* Add Message */}
                <form
                  onSubmit={handleAddCall}
                  style={{
                    padding: "20px 0",
                  }}
                >
                  <Typography p={"10px"} variant="h4" component="h4">
                    Schedule a New Call
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{
                        padding: "16px 20px",
                        border: "none",
                        outline: "none",
                        borderRadius: "5px",
                        backgroundColor: "rgb(0, 0, 0, 0.3)",
                        color: "white",
                        width: "80%",
                        marginRight: "20px",
                      }}
                      required
                      placeholder="Agenda"
                      value={agenda}
                      onChange={(e) => setAgenda(e.target.value)}
                      type="text"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="date-picker"
                        renderInput={(props) => <TextField {...props} />}
                        label="Date Time (In UTC)"
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <textarea
                    style={{
                      padding: "16px 20px",
                      border: "none",
                      outline: "none",
                      borderRadius: "5px",
                      backgroundColor: "rgb(0, 0, 0, 0.3)",
                      color: "white",
                      width: "100%",
                      marginTop: "10px",
                    }}
                    placeholder="Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    type="text"
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      varint="contained"
                      color="primary"
                      style={{
                        padding: "16px 20px",
                        backgroundColor: colors.greenAccent[300],
                      }}
                    >
                      ADD Call
                    </Button>
                  </div>
                  {/* <input type="text" /> */}
                </form>
              </TabPanel>
            </Box>
          </div>
        </Modal>
      </div>
    );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

export default Monitoring;
