import { Store } from "pullstate";
import { getCurrentTimeStamp } from "../helpers/sevenDaysTimestamp";
import { rteamIds } from "../helpers/rteamIds";
import { getLocalStorage } from "../helpers/localStorage";

export const store = new Store({
  followingsFilter_timestamp_items: [],
  followingsFilter_timestamp_enabled: false,
  followingsFilter_created_at_items: [],
  followingsFilter_created_at_enabled: false,
  followingsFilter_global_items: [
    {
      columnField: "pfp",
      operatorValue: "contains",
      value: "",
      id: 1,
    },
  ],
  followingsFilter_timestamp_clear: true,
  followingsFilter_created_at_clear: true,
  upperTimestampLimit: getCurrentTimeStamp(),
  lowerTimestampLimit: getCurrentTimeStamp() - 604800,
  upperTimestampLimit_handles: getCurrentTimeStamp(),
  lowerTimestampLimit_handles: getCurrentTimeStamp() - 31536000,
  upperTimestampLimit_tweets: getCurrentTimeStamp(),
  lowerTimestampLimit_tweets: getCurrentTimeStamp() - 2592000,
  rteamIds: getLocalStorage("rteamIds") ? getLocalStorage("rteamIds") : [],
  followingsColumns: getLocalStorage("followingsColumns")
    ? getLocalStorage("followingsColumns")
    : {},
  monitioringColumns: getLocalStorage("monitioringColumns")
    ? getLocalStorage("monitioringColumns")
    : {},
  repoListColumns: getLocalStorage("repoListColumns")
    ? getLocalStorage("repoListColumns")
    : {},
  userListColumns: getLocalStorage("userListColumns")
    ? getLocalStorage("userListColumns")
    : {},
  repoForksColumns: getLocalStorage("repoForksColumns")
    ? getLocalStorage("repoForksColumns")
    : {},
  userStarsColumns: getLocalStorage("userStarsColumns")
    ? getLocalStorage("userStarsColumns")
    : {},
  handlesColumns: getLocalStorage("handlesColumns")
    ? getLocalStorage("handlesColumns")
    : {},
  notificationsColumns: getLocalStorage("notificationsColumns")
    ? getLocalStorage("notificationsColumns")
    : {},
});
