import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import { useAuth } from "../../contexts/AuthContext";
import useMounted from "../../hooks/useMounted";
import styles from "./Login.module.css";

function Login() {
  const history = useHistory();
  const { signInWithGoogle, login, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const mounted = useMounted();

  return (
    <div className={styles.container}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsSubmitting(true);
          await login(email, password)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => setError(error.message))
            .finally(() => mounted.current && setIsSubmitting(false));
          // history.push("/dashboard");
        }}
      >
        <div className={styles.innerBox}>
          <h1 className={styles.heading}>Log In</h1>

          <div className={styles.containerIp}>
            <label>Email</label>
            <input
              type="email"
              value={email}
              name="email"
              autoComplete="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className={styles.containerIp}>
            <label>Password</label>
            <input
              type="password"
              value={password}
              name="password"
              autoComplete="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className={styles.footer}>
            <b className={styles.error}>{error}</b>
            {!currentUser ? (
              <button type="submit">Log In</button>
            ) : (
              <button disabled onClick={history.push("/")}>
                Already Logged In
              </button>
            )}
            {/* <b className={styles.text}>OR</b>
            <button
              onClick={() =>
                signInWithGoogle()
                  .then((user) => console.log(user))
                  .catch((error) => console.log(error))
              }
            >
              <GoogleIcon />
            </button> */}
            {/* <p>
              Don't have an account?{" "}
              <span>
                <Link to="/signup">Sign Up</Link>
              </span>
            </p> */}
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
