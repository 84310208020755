import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import Avatar from "@mui/material/Avatar";
import "../scenes.css";
import BasicDateRangePicker from "../unix";
import LoadingGif from "../../assets/loading.gif";
import { store } from "../../store/store";
import { toast } from "react-toastify";

const Tweets = () => {
  const timestamp_items = store.useState(
    (s) => s.followingsFilter_timestamp_items
  );
  const timestamp_enabled = store.useState(
    (s) => s.followingsFilter_timestamp_enabled
  );
  const created_at_items = store.useState(
    (s) => s.followingsFilter_created_at_items
  );
  const created_at_enabled = store.useState(
    (s) => s.followingsFilter_created_at_enabled
  );
  const global_items = store.useState((s) => s.followingsFilter_global_items);
  const timestamp_clear = store.useState(
    (s) => s.followingsFilter_timestamp_clear
  );
  const created_at_clear = store.useState(
    (s) => s.followingsFilter_created_at_clear
  );
  const upperTimestampLimit = store.useState(
    (s) => s.upperTimestampLimit_tweets
  );
  const lowerTimestampLimit = store.useState(
    (s) => s.lowerTimestampLimit_tweets
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [following, setFollowing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [lastVisible, setLastVisible] = useState("");
  const [loading, setLoading] = useState(true);
  const [toastShown, setToastShown] = useState(false);

  const data = [];

  function unixToReadable(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    if (month < 10) {
      month = "0" + month.toString();
    }
    var date = a.getDate();
    if (date < 10) {
      date = "0" + date.toString();
    }
    var hour = a.getHours();
    if (hour < 10) {
      hour = "0" + hour.toString();
    }
    var min = a.getMinutes();
    if (min < 10) {
      min = "0" + min.toString();
    }
    var sec = a.getSeconds();
    if (sec < 10) {
      sec = "0" + sec.toString();
    }
    var time =
      year + "/" + month + "/" + date + ", " + hour + ":" + min + ":" + sec;
    return time;
  }

  useEffect(() => {
    // getFollowings();
    setFirstLoad(true);
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [!loading, upperTimestampLimit, lowerTimestampLimit]);

  function createUnixTS(dateStr) {
    const date = new Date(dateStr);
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  async function getData() {
    setLoading(true);

    if (lastVisible === "") {
      setToastShown(false);
      console.log("Inside if");
      const first = query(
        collection(db, "TWEETS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        limit(2000)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];

      setLastVisible(last);

      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "", id: "" };
        data_object.pfp.profile_image_url =
          "https://e7.pngegg.com/pngimages/708/311/png-clipart-icon-logo-twitter-logo-twitter-logo-blue-social-media-thumbnail.png";
        data_object.pfp.username = follow[i].data.username;
        data_object.username = follow[i].data.username;
        data_object.pfp.id = follow[i].data.id;
        data_object.id = follow[i].data.id;
        data_object.text = follow[i].data.text;
        data_object.like_count = follow[i].data.public_metrics.like_count;
        data_object.retweet_count = follow[i].data.public_metrics.retweet_count;
        data_object.created_at = createUnixTS(follow[i].data.created_at);
        data_object.created_at_utc = unixToReadable(
          createUnixTS(follow[i].data.created_at)
        );
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    } else {
      console.log("Inside else");

      if (!lastVisible && !toastShown) {
        toast.success("Data loaded successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToastShown(true);
        return;
      }

      const first = query(
        collection(db, "TWEETS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        startAfter(lastVisible),
        limit(2000)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];

      setLastVisible(last);

      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "", id: "" };
        data_object.pfp.profile_image_url =
          "https://e7.pngegg.com/pngimages/708/311/png-clipart-icon-logo-twitter-logo-twitter-logo-blue-social-media-thumbnail.png";
        data_object.pfp.username = follow[i].data.username;
        data_object.username = follow[i].data.username;
        data_object.pfp.id = follow[i].data.id;
        data_object.id = follow[i].data.id;
        data_object.text = follow[i].data.text;
        data_object.like_count = follow[i].data.public_metrics.like_count;
        data_object.retweet_count = follow[i].data.public_metrics.retweet_count;
        data_object.created_at = createUnixTS(follow[i].data.created_at);
        data_object.created_at_utc = unixToReadable(
          createUnixTS(follow[i].data.created_at)
        );
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    }
  }

  function getFollowings() {
    const followingCollectionRef = collection(db, "TWEETS");
    getDocs(followingCollectionRef)
      .then((response) => {
        const follow = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        console.log(follow);
        for (let i = 0; i < follow.length; i++) {
          const data_object = {};
          data_object.pfp = { profile_image_url: "", username: "", id: "" };
          data_object.pfp.profile_image_url =
            "https://e7.pngegg.com/pngimages/708/311/png-clipart-icon-logo-twitter-logo-twitter-logo-blue-social-media-thumbnail.png";
          data_object.pfp.username = follow[i].data.username;
          data_object.username = follow[i].data.username;
          data_object.pfp.id = follow[i].data.id;
          data_object.id = follow[i].data.id;
          data_object.text = follow[i].data.text;
          data_object.like_count = follow[i].data.public_metrics.like_count;
          data_object.retweet_count =
            follow[i].data.public_metrics.retweet_count;
          data_object.created_at = createUnixTS(follow[i].data.created_at);
          data_object.created_at_utc = unixToReadable(
            createUnixTS(follow[i].data.created_at)
          );
          data_object.timestamp = follow[i].data.timestamp;
          data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
          data.push(data_object);
        }

        setIsLoading(false);
        setFollowing(data);
      })
      .catch((error) => console.log(error.message));
  }

  const columns = [
    {
      field: "pfp",
      headerName: "GoTo",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/${params.value.username}/status/${params.value.id}`}
            >
              <Avatar src={params.value.profile_image_url} />
            </a>
          </>
        );
      },
    },
    {
      field: "text",
      headerName: "Text",
      flex: 5,
      cellClassName: "name-column--cell",
    },
    {
      field: "username",
      headerName: "Tweet By",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "like_count",
      headerName: "Likes",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "retweet_count",
      headerName: "RTs",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },

    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "created_at_utc",
      headerName: "Creation (yyyy/mm/dd)",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "timestamp_utc",
      headerName: "Timestamp (yyyy/mm/dd)",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "created_at",
      headerName: "Creation (UNIX)",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
    },

    {
      field: "timestamp",
      headerName: "Timestamp (UNIX)",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
  ];
  const timestampFilterModelItems = () => {
    return timestamp_enabled ? timestamp_items : [];
  };

  const created_atFilterModelItems = () => {
    return created_at_enabled ? created_at_items : [];
  };
  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="RECENT TWEETS"
              subtitle="List of all recent tweets made by the twitter handles."
            />
            <img className="loading_image" src={LoadingGif} alt="loading" />
            <p className="loading_text">
              Please be Patient! Fetching all data!
            </p>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px" className="container_flex">
            <Header
              className="header_flex"
              title="RECENT TWEETS"
              subtitle="List of all recent tweets made by the twitter handles."
            />
            <BasicDateRangePicker
              lastVisibleCallback={setLastVisible}
              followingCallback={setFollowing}
              unix={"timestamp"}
              className="date_flex"
              is_tweet={true}
            />
            <BasicDateRangePicker unix={"created_at"} className="date_flex" />
          </Box>
          <Box m="20px">
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGridPro
                autoHeight
                rows={following}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                pagination
                rowsPerPageOptions={[50, 100, 300, 500]}
                //filterMode={"client"}
                onFilterModelChange={(model) => {
                  store.update((state) => {
                    // state.followingsFilter_timestamp_enabled = false;
                    //   state.followingsFilter_created_at_enabled = false;
                    if (!timestamp_clear || !created_at_clear) {
                      state.followingsFilter_timestamp_enabled = false;
                      state.followingsFilter_created_at_enabled = false;
                      state.followingsFilter_timestamp_items = [];
                      state.followingsFilter_created_at_items = [];
                      state.followingsFilter_timestamp_clear = true;
                      state.followingsFilter_created_at_clear = true;
                    } else state.followingsFilter_global_items = model.items;
                  });
                }}
                filterModel={{
                  items: [
                    ...timestampFilterModelItems(),
                    ...created_atFilterModelItems(),
                    ...global_items,
                  ],
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </main>
      </div>
    );
  }
};

export default Tweets;
