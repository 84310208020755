import { useTheme } from "@emotion/react";
import React from "react";
import { tokens } from "../theme";
import Chart from "react-apexcharts";
import { Box } from "@mui/material";

const DonutChart = ({ x, y, xName }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const options = {
    labels: x,

    chart: {
      height: 40,
      type: "donut",
      foreColor: "#fffff",
      fontFamily: "DM sans",
    },
    // 10 colors list
    colors: [
      "#f7b924",
      "#f83245",
      "#3f51b5",
      "#e91e63",
      "#9c27b0",
      "#673ab7",
      "#2196f3",
    ],
    dataLabels: {
      enabled: true,
      color: "#fff",
    },
    legend: {
      show: true,
      color: "white",
    },
    stroke: {
      colors: ["transparent"],
    },
    plotOptions: {
      pie: {
        donut: {
          size: "48%",
          background: "transparent",
          labels: {
            show: false,
            name: {
              show: true,
              fontSize: "18px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: false,
              color: "#fff",
            },
            total: {
              show: false,
              label: "Our Visitors",
              color: "#fff",
            },
          },
        },
      },
    },
    tooltip: {
      theme: "dark",
      fillSeriesColor: false,
    },
  };
  const series = y;

  console.log(x);

  return (
    <Box width={'100%'} backgroundColor={colors.primary[400]} my={4}>
      <Chart
        options={options}
        series={series}
        type="donut"
        width={"100%"}
        height={380}
      />
    </Box>
  );
};

export default DonutChart;
