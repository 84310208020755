import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../utils/init-firebase";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import LoadingGif from "../../assets/loading.gif";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { GridToolbar } from "@mui/x-data-grid";
import { store } from "../../store/store";
import { setLocalStorage } from "../../helpers/localStorage";
import { DataGridPro } from "@mui/x-data-grid-pro";

const Releases = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterModel, setFilterModel] = useState(
    JSON.parse(localStorage.getItem("filterReleases")) || { items: [] }
  );

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getData = async () => {
    const res = await (
      await getDocs(
        query(collection(db, "RELEASE"), orderBy("timestamp", "desc"))
      )
    ).docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setTableData(res);
    console.log(res);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    getData();

    return () => {
      setIsLoading(false);
    };
  }, []);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.1,
    },
    {
      field: "title",
      headerName: "Release Title",
      flex: 0.2,
      cellClassName: "name-column--cell",
    },
    {
      field: "desc",
      headerName: "Release Description",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <div
            className={`name-column--cell w-fit internal-cell ${
              params?.value == "Bug" ? "yellow" : "green"
            } `}
          >
            {params?.value}
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="RTP Releases"
              subtitle="Log page of all latest RT Portal bug fixes and new feature additions"
            />
          </Box>

          <img className="loading_image" src={LoadingGif} alt="loading" />
          <p className="loading_text">Please be Patient! Fetching all data!</p>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box
            m="20px"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
            className="container_flex"
          >
            <Header
              className="header_flex"
              title="RTP Releases"
              subtitle="Log page of all latest RT Portal bug fixes and new feature additions"
            />
          </Box>
          <Box m="20px">
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGridPro
                autoHeight
                rows={tableData}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                // on row click set the row details in the state in the setModalContent
                // loading={loading}
                // onRowsScrollEnd={handleOnRowsScrollEnd}
                // hideFooterPagination
                pagination
                rowsPerPageOptions={[50, 100, 300, 500]}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {
                  localStorage.setItem(
                    "filterReleases",
                    JSON.stringify(newFilterModel)
                  );
                  console.log(newFilterModel);
                  setFilterModel(newFilterModel);
                }}
                //filterMode={"client"}
                // onFilterModelChange={(model) => {
                //   store.update((state) => {
                //     console.log(model, state, "MODEL");
                //     // state.followingsFilter_timestamp_enabled = false;
                //     //   state.followingsFilter_created_at_enabled = false;
                //     // if (!timestamp_clear || !created_at_clear) {
                //     //   state.followingsFilter_timestamp_enabled = false;
                //     //   state.followingsFilter_created_at_enabled = false;
                //     //   state.followingsFilter_timestamp_items = [];
                //     //   state.followingsFilter_created_at_items = [];
                //     //   state.followingsFilter_timestamp_clear = true;
                //     //   state.followingsFilter_created_at_clear = true;
                //     // } else state.followingsFilter_global_items = model.items;
                //   });
                // }}
                // filterModel={{
                //   items: [
                //     ...timestampFilterModelItems(),
                //     ...created_atFilterModelItems(),
                //     ...global_items,
                //   ],
                // }}
                onColumnVisibilityModelChange={(model) => {
                  store.update((state) => {
                    state.monitioringColumns = model;
                    setLocalStorage("monitioringColumns", model);
                  });
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </main>
      </div>
    );
  }
};

export default Releases;
