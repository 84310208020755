import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Button, Typography } from "@mui/material";
import "../scenes.css";
import { store } from "../../store/store";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function BasicDateRangePicker({
  unix,
  lastVisibleCallback,
  followingCallback,
  is_handle,
  is_tweet,
  type
}) {
  const followingsFilter = store.useState((s) => s.followingsFilter);
  const startDate = new Date();

  let title = unix === "created_at" ? "Creation Filter" : "Timestamp Filter";

  var endDate = new Date();
  is_handle
    ? endDate.setDate(endDate.getDate() - 365)
    : is_tweet
    ? endDate.setDate(endDate.getDate() - 60)
    : endDate.setDate(endDate.getDate() - 7);

  const [value, setValue] = useState([endDate, startDate]);

  useEffect(() => {
    if (type === "git" && startDate && endDate) {
      store.update((s) => {
        s.lowerTimestampLimit = createUnixTS(value[0]);
        s.upperTimestampLimit = createUnixTS(value[1]);
      })
    }
  }, [])

  function createUnixTS(dateStr) {
    const date = new Date(dateStr);
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  return (
    <div className="ts_container">
      <div className="unix_ts_container">
        <Typography>
          <span className="unix_ts">{title}</span>
        </Typography>
      </div>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{ start: "Start Date", end: "End Date" }}
      >
        <DateRangePicker
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            // console.log("this is value", value);
            // unix === "timestamp"
            //   ? store.update((s) => {
            //       s.followingsFilter_timestamp_enabled = true;
            //       s.followingsFilter_timestamp_items = [
            //         {
            //           columnField: "timestamp",
            //           operatorValue: ">=",
            //           value: createUnixTS(String(newValue[0])),
            //           id: 1,
            //         },
            //         {
            //           columnField: "timestamp",
            //           operatorValue: "<=",
            //           value: createUnixTS(String(newValue[1])),
            //           id: 2,
            //         },
            //       ];
            //       s.followingsFilter_timestamp_clear = false;
            //     })
            //   : store.update((s) => {
            //       s.followingsFilter_created_at_enabled = true;
            //       s.followingsFilter_created_at_items = [
            //         {
            //           columnField: "created_at",
            //           operatorValue: ">=",
            //           value: createUnixTS(String(newValue[0])),
            //           id: 3,
            //         },
            //         {
            //           columnField: "created_at",
            //           operatorValue: "<=",
            //           value: createUnixTS(String(newValue[1])),
            //           id: 4,
            //         },
            //       ];
            //       s.followingsFilter_created_at_clear = false;
            //     });
            // console.log("this is followingsFilter", followingsFilter);
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
              <button
                className="apply_filter_btn"
                onClick={() => {
                  if (unix === "timestamp") {
                    lastVisibleCallback("");
                    followingCallback([]);
                    store.update((s) => {
                      s.lowerTimestampLimit = createUnixTS(value[0]);
                      s.upperTimestampLimit = createUnixTS(value[1]);
                    });
                  } else {
                    store.update((s) => {
                      s.followingsFilter_created_at_enabled = true;
                      s.followingsFilter_created_at_items = [
                        {
                          columnField: "created_at",
                          operatorValue: ">=",
                          value: createUnixTS(value[0]),
                          id: 3,
                        },
                        {
                          columnField: "created_at",
                          operatorValue: "<=",
                          value: createUnixTS(value[1]),
                          id: 4,
                        },
                      ];
                      s.followingsFilter_created_at_clear = false;
                    });
                  }
                }}
              >
                Apply
              </button>
              {unix === "created_at" ? (
                <Button
                  onClick={() => {
                    unix === "timestamp"
                      ? store.update((s) => {
                          s.followingsFilter_timestamp_enabled = false;
                          s.followingsFilter_timestamp_items = [];
                          s.followingsFilter_timestamp_clear = true;
                        })
                      : store.update((s) => {
                          s.followingsFilter_created_at_enabled = false;
                          s.followingsFilter_created_at_items = [];
                          s.followingsFilter_created_at_clear = true;
                        });
                  }}
                >
                  <span className="clear_filter">
                    <HighlightOffIcon />
                  </span>
                </Button>
              ) : null}
            </>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
