import { useTheme } from '@emotion/react';
import { Box } from '@mui/system'
import React from 'react'
import Chart from "react-apexcharts";
import { tokens } from '../theme';

const ColumnChart = ({x, y, xName}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const options = {
        grid: {
          show: true,
          borderColor: "rgba(0, 0, 0, .2)",
          color: "#777e89",
          strokeDashArray: 2,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        chart: {
          fontFamily: "DM Sans",
          foreColor: "#adb0bb",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
    
        xaxis: {
          categories: x,
        },
        markers: {
          size: 4,
          border: 1,
        },
        tooltip: {
          theme: "dark",
        },
        legend: {
          show: false,
        },
      };
      const series = [
        {
          name: xName,
          data: y,
        },
      ];

  return (
    <Box width={'100%'} backgroundColor={colors.primary[400]} my={4}>
    <Chart
      options={options}
      series={series}
      type="bar"
      width={"100%"}
      height={380}
    />
  </Box>
  )
}

export default ColumnChart