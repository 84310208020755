import React, { useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import PendingIcon from "@mui/icons-material/Pending";
import LoadingGif from "../../assets/loading.gif";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import {
  Diversity3,
  PeopleAlt,
  RecentActors,
  ThumbDown,
} from "@mui/icons-material";
import { db } from "../../utils/init-firebase";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import Chart from "react-apexcharts";
import ColumnChart from "../../components/ColumnChart";
import CollectionsIcon from "@mui/icons-material/Collections";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import TelegramIcon from "@mui/icons-material/Telegram";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import InputIcon from "@mui/icons-material/Input";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import DonutChart from "../../components/DonutChart";
import { teamId } from "../../data/teamId";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import { html2pdf } from "html2pdf.js";
import jsPDF from "jspdf";

const Analytics = () => {
  const theme = useTheme();
  const componentRef = useRef();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState([]);
  const [subDetails, setSubDetails] = useState(null);
  const [subDetails2, setSubDetails2] = useState(null);
  const [range, setRange] = useState(null);
  const [all, setAll] = useState([]);
  const [votesResult, setVotesResult] = useState([]);
  const [upvoted, setUpvoted] = useState();
  const [filterModel, setFilterModel] = useState();
  const [filterModel2, setFilterModel2] = useState();
  const rangeList = [
    "Last 24 hours",
    "Last 7 days",
    "Last 1 month",
    "Last 3 months",
    "Last 1 Year",
    "Last week",
    "This week",
    "Last Month",
    "This Month",
    "Last Quarter",
    "This Quarter",
    "Last Year",
    "This Year",
  ];
  const filterList = [
    {
      name: "Call Pending",
      items: [
        {
          columnField: "voting",
          operatorValue: "contains",
          id: 21349,
          value: "yes",
        },
        {
          columnField: "call",
          operatorValue: "contains",
          id: 45548,
          value: "pending",
        },
      ],
      linkOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
    {
      name: "Channel Pending",
      items: [
        {
          columnField: "voting",
          operatorValue: "contains",
          id: 21349,
          value: "yes",
        },
        {
          columnField: "channel",
          operatorValue: "contains",
          id: 45548,
          value: "pending",
        },
      ],
      linkOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
    {
      name: "Investment Pending",
      items: [
        {
          columnField: "voting",
          operatorValue: "contains",
          id: 21349,
          value: "yes",
        },
        {
          columnField: "channel",
          operatorValue: "contains",
          id: 45548,
          value: "yes",
        },
        {
          columnField: "invested",
          operatorValue: "contains",
          id: 7217,
          value: "pending",
        },
      ],
      linkOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
  ];
  const [call, setCall] = useState();
  const [channel, setChannel] = useState();
  const [invested, setInvested] = useState();
  const [pending, setPending] = useState();
  const [pendingCall, setPendingCall] = useState();
  const [total, setTotal] = useState();
  const [monthWise, setMonthWise] = useState({});
  const [projByMemb, setProjByMemb] = useState();
  const [projByMembTotal, setProjByMembTotal] = useState();
  const [upvoteProj, setUpvoteProj] = useState({});
  const [upvoteProjTotal, setUpvoteProjTotal] = useState();
  const [downvoteProj, setDownvoteProj] = useState({});
  const [downvoteProjTotal, setDownvoteProjTotal] = useState();
  const [channelMemb, setChannelMemb] = useState({});
  const [channelMembUn, setChannelMembUn] = useState({});
  const [channelMembTotal, setChannelMembTotal] = useState();
  const [channelMembTotalUn, setChannelMembTotalUn] = useState();
  {
    console.log(channelMembTotalUn, "TOTAL");
  }
  const [callMemb, setCallMemb] = useState({});
  const [callMembUn, setCallMembUn] = useState({});
  const [callMembPend, setCallMembPend] = useState({});
  const [callMembTotal, setCallMembTotal] = useState();
  const [callMembTotalUn, setCallMembTotalUn] = useState();
  const [callMembTotalPend, setCallMembTotalPend] = useState();
  const [reactions, setReactions] = useState([]);
  const [mList, setMList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subStartDate, setSubStartDate] = useState(null);
  const [subEndDate, setSubEndDate] = useState(null);
  const [subStartDate2, setSubStartDate2] = useState(null);
  const [subEndDate2, setSubEndDate2] = useState(null);
  const [member, setMember] = useState(null);
  const [update, setUpdate] = useState(false);

  function calculateDates(option) {
    var currentDate = new Date(); // Get the current date
    var startDate, endDate;

    switch (option) {
      case "Last 24 hours":
        startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours
        endDate = currentDate;
        break;
      case "Last 7 days":
        startDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days
        endDate = currentDate;
        break;
      case "Last 1 month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate()
        ); // Subtract 1 month
        endDate = currentDate;
        break;
      case "Last 3 months":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          currentDate.getDate()
        ); // Subtract 3 months
        endDate = currentDate;
        break;
      case "Last 1 Year":
        startDate = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        ); // Subtract 1 year
        endDate = currentDate;
        break;
      case "Last week":
        startDate = new Date(
          currentDate.getTime() -
            (currentDate.getDay() + 7) * 24 * 60 * 60 * 1000
        ); // Subtract current day + 7 days
        endDate = new Date(
          currentDate.getTime() - currentDate.getDay() * 24 * 60 * 60 * 1000
        ); // Subtract current day
        break;
      case "This week":
        startDate = new Date(
          currentDate.getTime() - currentDate.getDay() * 24 * 60 * 60 * 1000
        ); // Subtract current day
        endDate = currentDate;
        break;
      case "Last Month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        ); // First day of previous month
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        ); // Last day of previous month
        break;
      case "This Month":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ); // First day of current month
        endDate = currentDate;
        break;
      case "Last Quarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          1
        ); // First day of 3 months ago
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        ); // Last day of previous month
        break;
      case "This Quarter":
        startDate = new Date(
          currentDate.getFullYear(),
          Math.floor(currentDate.getMonth() / 3) * 3,
          1
        ); // First day of current quarter
        endDate = currentDate;
        break;
      case "Last Year":
        startDate = new Date(currentDate.getFullYear() - 1, 0, 1); // First day of previous year
        endDate = new Date(currentDate.getFullYear() - 1, 11, 31); // Last day of previous year
        break;
      case "This Year":
        startDate = new Date(currentDate.getFullYear(), 0, 1); // First day of current year
        endDate = currentDate;
        break;
      default:
        startDate = null;
        endDate = null;
    }

    return {
      startDate: startDate,
      endDate: endDate,
    };
  }

  const getData = async (updates) => {
    const collectionRef = collection(db, "MONITORING");
    var res = await getDocs(collectionRef).then((response) => {
      const d = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      return d;
    });
    console.log(res, "RES");
    res = res.map((detail) => ({
      ...detail,
      author: teamId[detail.author],
      team_reactions: detail.team_reactions?.map((team) => ({
        name: teamId[team.name],
        vote: team.vote,
      })),
    }));
    setAll(
      res.map((detail) => ({
        ...detail,
        author: teamId[detail.author],
        team_reactions: detail.team_reactions?.map((team) => ({
          ...team,
          name: teamId[team.name],
        })),
      }))
    );
    res?.map((detail) => {
      if (teamId[detail.author] === undefined) {
        console.log(detail, "FILTERS");
      }
    });
    // console.log(
    //   r,
    //   "FILTERED ARRAY"
    // );
    setDetails(res);
    setTotal(res?.length);
    // console.log(res?.reduce((acc, detail) => acc +  detail?.reaction_count?.upvote, 0))

    setUpvoted(res?.filter((detail) => detail?.voting === "YES")?.length);
    setCall(res?.filter((detail) => detail?.call === "YES")?.length);
    setChannel(res?.filter((detail) => detail?.channel === "YES")?.length);
    setInvested(res?.filter((detail) => detail?.invested === "YES")?.length);
    setPending(res?.filter((detail) => detail?.voting === "PENDING"));
    setPendingCall(
      res?.filter(
        (detail) => detail?.voting === "YES" && detail?.call === "PENDING"
      )?.length
    );
    // console.log(res);
    setProjByMemb(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          acc[detail?.author] = acc[detail?.author] + 1;
        } else {
          acc[detail?.author] = 1;
        }
        return acc;
      }, {})
    );
    setProjByMembTotal(
      res?.reduce((acc, detail) => {
        if (detail?.author) {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setMonthWise(
      res?.reduce((acc, detail) => {
        if (acc[detail?.month_name]) {
          acc[detail?.month_name] = acc[detail?.month_name] + 1;
        } else {
          acc[detail?.month_name] = 1;
        }
        return acc;
      }, {})
    );
    setUpvoteProj(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.voting === "YES") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.voting === "YES") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setUpvoteProjTotal(
      res?.reduce((acc, detail) => {
        if (detail?.voting === "YES") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setDownvoteProj(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.voting === "NO") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.voting === "NO") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setDownvoteProjTotal(
      res?.reduce((acc, detail) => {
        if (detail?.voting === "NO") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setChannelMemb(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.channel === "YES") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.channel === "YES") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setChannelMembUn(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.channel === "NO") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.channel === "NO") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setChannelMembTotal(
      res?.reduce((acc, detail) => {
        if (detail?.channel === "YES") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setChannelMembTotalUn(
      res?.reduce((acc, detail) => {
        if (detail?.channel === "NO") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setCallMemb(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.call === "YES") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.call === "YES") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setCallMembUn(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.call === "NO") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.call === "NO") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setCallMembPend(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.call === "PENDING") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.call === "PENDING") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setCallMembTotal(
      res?.reduce((acc, detail) => {
        if (detail?.call === "YES") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setCallMembTotalUn(
      res?.reduce((acc, detail) => {
        if (detail?.call === "NO") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setCallMembTotalPend(
      res?.reduce((acc, detail) => {
        if (detail?.call === "PENDING") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );

    var temp = [];
    res.map((element) => {
      if (element?.team_reactions) {
        console.log(element?.team_reactions, "TEAAMMMMMMm");
        temp.push(...element?.team_reactions);
      }
    });
    setReactions(temp);
    var reaacccttt = temp?.reduce((acc, team) => {
      if (acc[team?.name]) {
        if (team.vote == "YES") {
          acc[team?.name].votedYes = acc[team?.name].votedYes + 1;
        } else {
          acc[team?.name].votedNo = acc[team?.name].votedNo + 1;
        }
      } else {
        acc[team?.name] = { votedYes: 0, votedNo: 0 };
        if (team.vote == "YES") {
          acc[team?.name].votedYes = acc[team?.name].votedYes + 1;
        } else {
          acc[team?.name].votedNo = acc[team?.name].votedNo + 1;
        }
      }
      return acc;
    }, {});
    const dataArray = Object.entries(reaacccttt).map(
      ([name, votes], index) => ({
        id: index + 1,
        name,
        ...votes,
      })
    );

    setVotesResult(dataArray);
    console.log(dataArray, "CUSTOM FILTERED");
    const val = new Set(temp?.map((react) => react?.name));
    setMList([...val]);
    // setMList(new Set(temp?.map(react => react?.name)))
    setIsLoading(false);
    if (updates) {
      setUpdate(true);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (update) {
      console.log("UPDATES ");
      onApply({ clear: false });
      onSubApply2({ clear: false });
      onSubApply({ clear: false });
      setUpdate(false);
    }
  }, [all, update]);

  const onApply = ({ clear = false }) => {
    var res;
    if (clear) {
      setStartDate(null);
      setEndDate(null);
      setRange(null);
      res = all;
      setDetails(all);
    } else {
      if (startDate !== null && endDate !== null) {
        console.log("1", new Date(startDate), new Date(endDate));
        res = all?.filter(
          (detail) =>
            new Date(startDate) <= new Date(detail?.date) &&
            new Date(endDate) >= new Date(detail?.date)
        );
        setDetails(res);
      } else if (startDate) {
        console.log("2");
        res = all?.filter(
          (detail) => new Date(startDate) <= new Date(detail?.date)
        );
        setDetails(res);
      } else if (endDate) {
        console.log("3");
        res = all?.filter(
          (detail) => new Date(endDate) >= new Date(detail?.date)
        );
        setDetails(res);
      } else {
        res = all;
        setDetails(all);
      }
    }

    console.log(res);
    setTotal(res?.length);
    // console.log(res?.reduce((acc, detail) => acc +  detail?.reaction_count?.upvote, 0))

    setUpvoted(res?.filter((detail) => detail?.voting === "YES")?.length);
    setCall(res?.filter((detail) => detail?.call === "YES")?.length);
    setChannel(res?.filter((detail) => detail?.channel === "YES")?.length);
    setInvested(res?.filter((detail) => detail?.invested === "YES")?.length);
    setPending(res?.filter((detail) => detail?.voting === "PENDING"));
    setPendingCall(
      res?.filter(
        (detail) => detail?.voting === "YES" && detail?.call === "PENDING"
      )?.length
    );
    console.log(res);
    setProjByMemb(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          acc[detail?.author] = acc[detail?.author] + 1;
        } else {
          acc[detail?.author] = 1;
        }
        return acc;
      }, {})
    );
    setMonthWise(
      res?.reduce((acc, detail) => {
        if (acc[detail?.month_name]) {
          acc[detail?.month_name] = acc[detail?.month_name] + 1;
        } else {
          acc[detail?.month_name] = 1;
        }
        return acc;
      }, {})
    );
    setUpvoteProj(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.voting === "YES") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.voting === "YES") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setUpvoteProjTotal(
      res?.reduce((acc, detail) => {
        if (detail?.voting === "YES") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setDownvoteProj(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.voting === "NO") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.voting === "NO") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setChannelMemb(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.call === "YES") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.call === "YES") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setChannelMembUn(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.channel === "NO") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.channel === "NO") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setChannelMembTotal(
      res?.reduce((acc, detail) => {
        if (detail?.channel === "YES") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setChannelMembTotalUn(
      res?.reduce((acc, detail) => {
        if (detail?.channel === "NO") {
          acc = acc + 1;
        }

        return acc;
      }, 0)
    );
    setCallMemb(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.call === "YES") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.call === "YES") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setCallMembUn(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.call === "NO") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.call === "NO") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );
    setCallMembPend(
      res?.reduce((acc, detail) => {
        if (acc[detail?.author]) {
          if (detail?.call === "PENDING") {
            acc[detail?.author] = acc[detail?.author] + 1;
          }
        } else {
          acc[detail?.author] = 0;
          if (detail?.call === "PENDING") {
            acc[detail?.author] = 1;
          }
        }
        return acc;
      }, {})
    );

    var temp = [];
    res.map((element) => {
      if (element?.team_reactions) {
        console.log(element?.team_reactions);
        temp.push(...element?.team_reactions);
      }
    });
    setReactions(temp);
    var reaacccttt = temp?.reduce((acc, team) => {
      if (acc[team?.name]) {
        if (team.vote == "YES") {
          acc[team?.name].votedYes = acc[team?.name].votedYes + 1;
        } else {
          acc[team?.name].votedNo = acc[team?.name].votedNo + 1;
        }
      } else {
        acc[team?.name] = { votedYes: 0, votedNo: 0 };
        if (team.vote == "YES") {
          acc[team?.name].votedYes = acc[team?.name].votedYes + 1;
        } else {
          acc[team?.name].votedNo = acc[team?.name].votedNo + 1;
        }
      }
      return acc;
    }, {});
    const dataArray = Object.entries(reaacccttt).map(
      ([name, votes], index) => ({
        id: index + 1,
        name,
        ...votes,
      })
    );

    setVotesResult(dataArray);
    const val = new Set(temp?.map((react) => react?.name));
    setMList([...val]);
    // setMList(new Set(temp?.map(react => react?.name)))
    setIsLoading(false);
    onSubApply({ clear: false });
  };

  console.log(reactions, "REACTIONS");

  const onSubApply2 = ({ clear = false }) => {
    var res;
    if (clear) {
      setSubStartDate2(null);
      setSubEndDate2(null);
      res = all;
      setSubDetails2([]);
      // setDetails(details);
    } else {
      if (subStartDate2 !== null && subEndDate2 !== null) {
        console.log("1", new Date(subStartDate2), new Date(subEndDate2));
        res = all?.filter(
          (detail) =>
            new Date(subStartDate2) <= new Date(detail?.date) &&
            new Date(subEndDate2) >= new Date(detail?.date)
        );
        setSubDetails2(res);
      } else if (subStartDate2) {
        console.log("2");
        res = all?.filter(
          (detail) => new Date(subStartDate2) <= new Date(detail?.date)
        );
        setSubDetails2(res);
      } else if (subEndDate2) {
        console.log("3");
        res = all?.filter(
          (detail) => new Date(subEndDate2) >= new Date(detail?.date)
        );
        setSubDetails2(res);
      } else {
        res = all;
        setSubDetails2(all);
      }
    }
    setIsLoading(false);
  };

  const onSubApply = ({ clear = false }) => {
    var res;
    if (clear) {
      setSubStartDate(null);
      setSubEndDate(null);
      res = all;
      setSubDetails(null);
      // setDetails(details);
    } else {
      if (subStartDate !== null && subEndDate !== null) {
        console.log("1", new Date(subStartDate), new Date(subEndDate));
        res = all?.filter(
          (detail) =>
            new Date(subStartDate) <= new Date(detail?.date) &&
            new Date(subEndDate) >= new Date(detail?.date)
        );
        setSubDetails(res);
      } else if (subStartDate) {
        console.log("2");
        res = all?.filter(
          (detail) => new Date(subStartDate) <= new Date(detail?.date)
        );
        setSubDetails(res);
      } else if (subEndDate) {
        console.log("3");
        res = all?.filter(
          (detail) => new Date(subEndDate) >= new Date(detail?.date)
        );
        setSubDetails(res);
      } else {
        res = all;
        setSubDetails(all);
      }
    }

    // if (res) {
    //   console.log(res);
    //   var temp = [];
    //   res.map((element) => {
    //     if (element?.team_reactions) {
    //       console.log(element?.team_reactions);
    //       temp.push(...element?.team_reactions);
    //     }
    //   });
    //   setReactions(temp);
    // }
    // setIsLoading(false);
  };

  const handleVotingChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      voting: value,
    });
    getData(true);
  };

  const handleCategoryChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      category: value,
    });
    getData(true);
  };

  const handleCallChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      call: value,
    });
    getData(true);
  };

  const handleChannelChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      channel: value,
    });
    getData(true);
  };

  const handleInvestedChange = async (event, id) => {
    const value = event.target.value;
    const res = await updateDoc(doc(db, "MONITORING", `${id}`), {
      invested: value,
    });
    getData(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <div
            className="name-column--cell internal-cell"
            style={{
              width: "100% !important",
              height: "100% !important",
            }}
          >
            {params?.value}
          </div>
        );
      },
    },
    {
      field: "twitter",
      headerName: "Twitter",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.value}`}
            >
              {params?.value}
            </a>
          </>
        );
      },
    },
    {
      field: "website",
      headerName: "Website",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.value}`}
            >
              {params?.value}
            </a>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.4,
    },
    {
      field: "message_url",
      headerName: "GoTo Discord",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.value}`}
            >
              <Button variant="contained">Discord</Button>
            </a>
          </>
        );
      },
    },
    {
      field: "voting",
      headerName: "Voting",
      headerAlign: "center",
      flex: 0.4,
      alignItems: "center",
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Voting"
            onChange={(e) => handleVotingChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "call",
      headerName: "Call",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Call"
            onChange={(e) => handleCallChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "channel",
      headerName: "Channel",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Channel"
            onChange={(e) => handleChannelChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "invested",
      headerName: "Invested",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Invested"
            onChange={(e) => handleInvestedChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
  ];

  const votesColumns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.4,
    },
    {
      field: "votedYes",
      headerName: "Voted Yes",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div
            className="name-column--cell--center"
            style={{
              width: "100% !important",
            }}
          >
            {params?.value +
              details?.filter((react) => react.author == params?.row?.name)
                ?.length}
          </div>
        );
      },
    },
    {
      field: "votedNo",
      headerName: "Voted No",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.4,
    },
    {
      field: "votedNot",
      headerAlign: "center",
      headerName: "Not Voted",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div
            className="name-column--cell--center"
            style={{
              width: "100% !important",
            }}
          >
            {/* {console.log(params)} */}
            {/* {details?.length} */}
            {/* {params?.value} */}
            {Math.max(
              details?.length -
                params?.row?.votedYes -
                params?.row?.votedNo -
                details?.filter((react) => react.author == params?.row?.name)
                  ?.length,
              0
            )}
          </div>
        );
      },
    },
  ];

  const newColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <div
            className="name-column--cell internal-cell"
            style={{
              width: "100% !important",
              height: "100% !important",
            }}
          >
            {params?.value}
          </div>
        );
      },
    },
    {
      field: "twitter",
      headerName: "Twitter",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.value}`}
            >
              {params?.value}
            </a>
          </>
        );
      },
    },
    {
      field: "website",
      headerName: "Website",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.value}`}
            >
              {params?.value}
            </a>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.4,
    },
    {
      field: "message_url",
      headerName: "GoTo Discord",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.value}`}
            >
              <Button variant="contained">Discord</Button>
            </a>
          </>
        );
      },
    },
    {
      field: "author",
      headerName: "Author",
      headerAlign: "center",
      flex: 0.4,
      alignItems: "center",
    },
    {
      field: "voting",
      headerName: "Voting",
      headerAlign: "center",
      flex: 0.4,
      alignItems: "center",
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Voting"
            onChange={(e) => handleVotingChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "call",
      headerName: "Call",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Call"
            onChange={(e) => handleCallChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "channel",
      headerName: "Channel",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Channel"
            onChange={(e) => handleChannelChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "invested",
      headerName: "Invested",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={params.value}
            className={`${
              params.value === "YES"
                ? "green"
                : params.value === "PENDING"
                ? "yellow"
                : "red"
            } w-full`}
            label="Invested"
            onChange={(e) => handleInvestedChange(e, params?.id)}
          >
            <MenuItem value={"YES"}>YES</MenuItem>
            <MenuItem value={"NO"}>NO</MenuItem>
            <MenuItem value={"PENDING"}>
              <PendingIcon />
            </MenuItem>
          </Select>
        );
      },
    },
  ];

  const columnsNew = [
    {
      field: "name",
      headerName: "Member Name",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell--center",
      alignItems: "center",
    },
    {
      field: "value",
      headerName: "No of projects",
      headerAlign: "center",
      flex: 0.4,
      cellClassName: "name-column--cell--center",
      alignItems: "center",
    },
    {
      field: "percentage",
      headerName: "Percentage",
      headerAlign: "center",
      cellClassName: "name-column--cell--center",
      flex: 0.4,
      alignItems: "center",
    },
  ];

  const GRAPH = ({ member }) => {
    const [list, setList] = useState({});

    useEffect(() => {
      var val = details
        ?.filter((react) => react.author == member)
        ?.reduce((acc, detail) => {
          if (acc[detail?.month_name]) {
            acc[detail?.month_name] = acc[detail?.month_name] + 1;
          } else {
            acc[detail?.month_name] = 1;
          }
          return acc;
        }, {});
      setList(val);
    }, [member]);

    return (
      <ColumnChart
        x={Object.keys(list)}
        y={Object.values(list)}
        xName="Projects"
      />
    );
  };

  const handleDownloadPDF = async () => {
    const componentNode = componentRef.current;

    // Create a canvas from the component's DOM
    const canvas = await html2canvas(componentNode);

    // Create a PDF instance
    const pdf = new jsPDF("p", "mm", "a4");

    // Calculate the width and height of the PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };

    // Set the background color to #141b2d (dark blueish)
    const bgColorHex = "#141b2d";
    const [r, g, b] = hexToRgb(bgColorHex);
    pdf.setFillColor(r, g, b);
    pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

    // Add the canvas to the PDF
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      0,
      0,
      pdfWidth,
      pdfHeight
    );

    // Save the PDF with the name 'my_component.pdf'
    pdf.save("my_component.pdf");
  };

  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="MONITORING ANALYTICS"
              subtitle="Welcome to your monitoring analytics dashboard"
            />
            <img className="loading_image" src={LoadingGif} alt="loading" />
            <p className="loading_text">
              Please be Patient! Fetching all data!
            </p>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title="MONITORING ANALYTICS"
                subtitle="Welcome to your monitoring analytics dashboard"
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "48px",
                }}
              >
                <Box mr={2} width={160}>
                  <FormControl fullWidth>
                    <InputLabel
                      style={{
                        fontSize: 16,
                      }}
                      id="demo-simple-select-label"
                    >
                      Select Range
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={range}
                      style={{
                        border: "1px solid white",
                        fontSize: 16,
                        height: "48px",
                      }}
                      label="Range"
                      onChange={(event) => {
                        setRange(event.target.value);
                        const { startDate: start, endDate: end } =
                          calculateDates(event.target.value);
                        setStartDate(start);
                        setEndDate(end);
                      }}
                    >
                      {rangeList?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    style={{
                      height: "48px",
                    }}
                    className="date"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    className="date"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <button className="apply_filter_btn" onClick={onApply}>
                  Apply
                </button>
                <span
                  className="clear_filter"
                  onClick={() => {
                    onApply({ clear: true });
                  }}
                >
                  <HighlightOffIcon />
                </span>
                <button
                  type="button"
                  onClick={handleDownloadPDF}
                  className="apply_filter_btn"
                >
                  Download
                </button>
                {/* <ReactToPrint
                  trigger={() => (
                    <button className="apply_filter_btn">Download</button>
                  )}
                  content={() => componentRef.current}
                /> */}
              </div>
            </Box>
            <div style={{ backgroundColor: "#141b2d" }} ref={componentRef}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h4"
                >
                  General Analytics
                </Typography>
              </Box>
              {/* GRID & CHARTS */}
              {/* <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="140px"
              gap="20px"
            > */}
              <Grid width={"100%"} container spacing={2}>
                {/* ROW 1 */}
                <Grid item xs={12} lg={3}>
                  <Box
                    paddingY={3}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={details?.length}
                      subtitle="Total Projects"
                      progress="1"
                      increase=""
                      icon={
                        <CollectionsIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Box
                    paddingY={3}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={pending?.length}
                      subtitle="Pending Voting"
                      progress={pending?.length / details?.length}
                      increase=""
                      icon={
                        <PendingActionsIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Box
                    paddingY={3}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={pendingCall}
                      subtitle="Pending Call"
                      progress={pendingCall / details?.length}
                      increase=""
                      icon={
                        <PhonePausedIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Box
                    gridColumn="span 3"
                    paddingY={3}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={upvoted}
                      subtitle="Upvoted projects"
                      progress={upvoted / total}
                      increase=""
                      desc={`= ${(
                        (upvoted / total) *
                        100
                      )?.toFixed()}% of Total Projects`}
                      icon={
                        <ThumbUpIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Box
                    gridColumn="span 3"
                    paddingY={3}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={details?.length - upvoted}
                      subtitle="Downvoted projects"
                      progress={(details?.length - upvoted) / total}
                      increase=""
                      desc={`= ${(
                        ((details?.length - upvoted) / total) *
                        100
                      )?.toFixed()}% of Total Projects`}
                      icon={
                        <ThumbDown
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Box
                    paddingY={3}
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={call}
                      subtitle="Call Executed"
                      progress={call / upvoted}
                      increase=""
                      desc={`= ${(
                        (call / upvoted) *
                        100
                      )?.toFixed()}% of Upvoted Projects`}
                      icon={
                        <ContactPhoneIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Box
                    paddingY={3}
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={channel}
                      subtitle="TG channel"
                      progress={channel / call}
                      increase=""
                      desc={`= ${(
                        (channel / call) *
                        100
                      )?.toFixed()}% of call executed Projects`}
                      icon={
                        <TelegramIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Box
                    paddingY={3}
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title={invested}
                      subtitle="Invested"
                      progress={invested / channel}
                      increase=""
                      desc={`= ${(
                        (invested / channel) *
                        100
                      )?.toFixed()}% of TG Channel Projects`}
                      icon={
                        <PaidIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>
                {/* Row 3 End */}
                {/* </Box> */}
              </Grid>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                  mt={1}
                  mb={-2}
                >
                  Number of projects posted each month by WWV Team:-
                </Typography>
              </Box>
              <ColumnChart
                x={Object.keys(monthWise)}
                y={Object.values(monthWise)}
                xName="Projects"
              />
              {/* Date SSELECTOR  */}
              {/* <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              mt={2}
            >
              <Typography
                textTransform={"uppercase"}
                fontWeight={600}
                variant="h6"
              >
                Select date range for detailed analysis
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 12,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    className="date"
                    value={subStartDate2}
                    onChange={(newValue) => {
                      setSubStartDate2(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    className="date"
                    value={subEndDate2}
                    onChange={(newValue) => {
                      setSubEndDate2(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <button className="apply_filter_btn" onClick={onSubApply2}>
                  Apply
                </button>
                <span
                  className="clear_filter"
                  onClick={() => {
                    onSubApply2({ clear: true });
                  }}
                >
                  <HighlightOffIcon />
                </span>
              </div>
            </Box> */}
              {/* {subDetails2 && ( */}
              {details && (
                <>
                  <Box
                    className="container_flex"
                    gap={2}
                    justifyContent={"space-between"}
                  >
                    <Box className="container_flex" gap={2}>
                      {filterList?.map((item, index) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 4,
                          }}
                        >
                          <Button
                            onClick={() => {
                              setFilterModel2(item);
                            }}
                            variant={
                              JSON.stringify(item) ===
                              JSON.stringify(filterModel2)
                                ? "contained"
                                : "outlined"
                            }
                            color="info"
                          >
                            {item.name}
                          </Button>
                        </div>
                      ))}
                    </Box>
                    <Button
                      onClick={() => {
                        setFilterModel2({ items: [] });
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Clear All Filters
                    </Button>
                  </Box>
                  <Box>
                    <Box
                      m="0 0 0 0"
                      mb={2}
                      sx={{
                        "& .MuiDataGrid-root": {
                          border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                          color: `${colors.grey[100]} !important`,
                        },
                      }}
                    >
                      <DataGridPro
                        autoHeight
                        rows={details}
                        columns={newColumns}
                        components={{ Toolbar: GridToolbar }}
                        // on row click set the row details in the state in the setModalContent
                        // loading={loading}
                        // onRowsScrollEnd={handleOnRowsScrollEnd}
                        // hideFooterPagination
                        pagination
                        // rowsPerPageOptions={[10, 20, 30, 500]}
                        pageSize={10}
                        //filterMode={"client"}
                        filterModel={filterModel2}
                        onFilterModelChange={(newFilterModel) => {
                          // localStorage.setItem(
                          //   "filterMonitoring",
                          //   JSON.stringify(newFilterModel)
                          // );
                          console.log(newFilterModel);
                          setFilterModel2(newFilterModel);
                        }}
                        // onFilterModelChange={(model) => {
                        //   store.update((state) => {
                        //     console.log(model, state, "MODEL");
                        //     // state.followingsFilter_timestamp_enabled = false;
                        //     //   state.followingsFilter_created_at_enabled = false;
                        //     if (!timestamp_clear || !created_at_clear) {
                        //       state.followingsFilter_timestamp_enabled = false;
                        //       state.followingsFilter_created_at_enabled = false;
                        //       state.followingsFilter_timestamp_items = [];
                        //       state.followingsFilter_created_at_items = [];
                        //       state.followingsFilter_timestamp_clear = true;
                        //       state.followingsFilter_created_at_clear = true;
                        //     } else state.followingsFilter_global_items = model.items;
                        //   });
                        // }}
                        // filterModel={{
                        //   items: [
                        //     ...timestampFilterModelItems(),
                        //     ...created_atFilterModelItems(),
                        //     ...global_items,
                        //   ],
                        // }}
                        // onColumnVisibilityModelChange={(model) => {
                        //   store.update((state) => {
                        //     state.monitioringColumns = model;
                        //     setLocalStorage("monitioringColumns", model);
                        //   });
                        // }}
                        // columnVisibilityModel={monitioringColumns}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "timestamp", sort: "desc" }],
                          },
                        }}
                        componentsProps={{
                          toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </div>
            <hr mb={4}></hr>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              mt={2}
            >
              <Typography
                textTransform={"uppercase"}
                fontWeight={600}
                variant="h4"
              >
                Overall Team Analytics
              </Typography>
            </Box>
            {/* VOTTING Call */}
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Overall Voting Analytics of Each Team member:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    {console.log(votesResult, "VOTES REDULR")}
                    <DataGridPro
                      autoHeight
                      rows={votesResult?.filter(
                        (vote) => vote.name !== "RTP Monitoring Bot"
                      )}
                      // rows={Object.keys(callMembPend)?.map((key, index) => ({
                      //   id: index,
                      //   name: key,
                      //   value: callMembPend[key],
                      //   percentage: `${(
                      //     (callMembPend[key] / callMembTotalPend) *
                      //     100
                      //   )?.toFixed(1)}%`,
                      // }))}
                      columns={votesColumns}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={15}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <br />
                {/* VOTED YES */}
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Projects voted YES by each team member:-
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.name)}
                    y={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.votedYes)}
                    xName="Votes Yes"
                  />
                  <DonutChart
                    x={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.name)}
                    y={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.votedYes)}
                    xName="Votes Yes"
                  />
                </Box>
                {/* VOTED NO */}
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Projects voted NO by each team member:-
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.name)}
                    y={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.votedYes)}
                    xName="Votes No"
                  />
                  <DonutChart
                    x={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.name)}
                    y={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.votedYes)}
                    xName="Votes No"
                  />
                </Box>
                {/* NOTE VOTED */}
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Projects not voted by each team member:-
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.name)}
                    y={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) =>
                        Math.max(
                          details?.length - vote.votedYes - vote.votedNo,
                          0
                        )
                      )}
                    xName="Not Voted"
                  />
                  <DonutChart
                    x={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) => vote.name)}
                    y={votesResult
                      ?.filter((vote) => vote.name !== "RTP Monitoring Bot")
                      ?.map((vote) =>
                        Math.max(
                          details?.length - vote.votedYes - vote.votedNo,
                          0
                        )
                      )}
                    xName="Not Voted"
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects posted by each Team member:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(projByMemb)}
                    y={Object.values(projByMemb)}
                    xName="Projects"
                  />
                  <DonutChart
                    title="PERCENTAGE OF PROJECTS POSTED BY EACH TEAM MEMBER
              "
                    x={Object.keys(projByMemb)}
                    y={Object.values(projByMemb)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(projByMemb)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: projByMemb[key],
                        percentage: `${(
                          (projByMemb[key] / projByMembTotal) *
                          100
                        )?.toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* Upvoted */}
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects posted by each Team member which was
                  upvoted:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(upvoteProj)}
                    y={Object.values(upvoteProj)}
                    xName="Projects"
                  />
                  <DonutChart
                    x={Object.keys(upvoteProj)}
                    y={Object.values(upvoteProj)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    {console.log(
                      Object.keys(upvoteProj)?.map((key) => ({
                        name: key,
                        value: upvoteProj[key],
                        precentage: `${Math.round(
                          (upvoteProj[key] / upvoteProjTotal) * 100
                        )}%`,
                      })),
                      "UPVOTYES COLUMN",
                      upvoteProjTotal
                    )}
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(upvoteProj)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: upvoteProj[key],
                        percentage: `${(
                          (upvoteProj[key] / upvoteProjTotal) *
                          100
                        )?.toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* DOWN VOTED */}
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects posted by each Team member which was
                  downvoted:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(downvoteProj)}
                    y={Object.values(downvoteProj)}
                    xName="Projects"
                  />
                  <DonutChart
                    x={Object.keys(downvoteProj)}
                    y={Object.values(downvoteProj)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(downvoteProj)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: downvoteProj[key],
                        percentage: `${(
                          (downvoteProj[key] / downvoteProjTotal) *
                          100
                        ).toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects with successful call execution by
                  each team member:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(callMemb)}
                    y={Object.values(callMemb)}
                    xName="Projects"
                  />
                  <DonutChart
                    x={Object.keys(callMemb)}
                    y={Object.values(callMemb)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(callMemb)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: callMemb[key],
                        percentage: `${(
                          (callMemb[key] / callMembTotal) *
                          100
                        )?.toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* Unsuccessfull Call */}
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects with unsuccessful call execution by
                  each team member:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(callMembUn)}
                    y={Object.values(callMembUn)}
                    xName="Projects"
                  />
                  <DonutChart
                    x={Object.keys(callMembUn)}
                    y={Object.values(callMembUn)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(callMembUn)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: callMembUn[key],
                        percentage: `${(
                          (callMembUn[key] / callMembTotalUn) *
                          100
                        )?.toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* Pending Call */}
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects with pending call execution by
                  each team member:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(callMembUn)}
                    y={Object.values(callMembUn)}
                    xName="Projects"
                  />
                  <DonutChart
                    x={Object.keys(callMembUn)}
                    y={Object.values(callMembUn)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(callMembPend)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: callMembPend[key],
                        percentage: `${(
                          (callMembPend[key] / callMembTotalPend) *
                          100
                        )?.toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* Successful channel */}
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects with successful channel execution by
                  each team member:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(channelMemb)}
                    y={Object.values(channelMemb)}
                    xName="Projects"
                  />
                  <DonutChart
                    x={Object.keys(channelMemb)}
                    y={Object.values(channelMemb)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(channelMemb)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: channelMemb[key],
                        percentage: `${(
                          (channelMemb[key] / channelMembTotal) *
                          100
                        )?.toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* UnSuccessful channel */}
            <Accordion
              style={{
                backgroundColor: "inherit",
                marginBottom: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                >
                  Total number of projects with unsuccessful channel execution
                  by each team member:-
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  gap={2}
                >
                  <ColumnChart
                    x={Object.keys(channelMembUn)}
                    y={Object.values(channelMembUn)}
                    xName="Projects"
                  />
                  <DonutChart
                    x={Object.keys(channelMembUn)}
                    y={Object.values(channelMembUn)}
                    xName="Projects"
                  />
                </Box>
                <Box>
                  <Box
                    m="0 0 0 0"
                    height="auto"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                      },
                    }}
                  >
                    <DataGridPro
                      autoHeight
                      rows={Object.keys(channelMembUn)?.map((key, index) => ({
                        id: index,
                        name: key,
                        value: channelMembUn[key],
                        percentage: `${(
                          (channelMembUn[key] / channelMembTotalUn) *
                          100
                        )?.toFixed(1)}%`,
                      }))}
                      columns={columnsNew}
                      components={{ Toolbar: GridToolbar }}
                      // on row click set the row details in the state in the setModalContent
                      // loading={loading}
                      // onRowsScrollEnd={handleOnRowsScrollEnd}
                      // hideFooterPagination
                      pagination
                      pageSize={10}
                      rowsPerPageOptions={[50, 100, 300, 500]}
                      //filterMode={"client"}
                      filterModel={filterModel}
                      onFilterModelChange={(newFilterModel) => {
                        // localStorage.setItem(
                        //   "filterMonitoring",
                        //   JSON.stringify(newFilterModel)
                        // );
                        console.log(newFilterModel);
                        setFilterModel(newFilterModel);
                      }}
                      // onFilterModelChange={(model) => {
                      //   store.update((state) => {
                      //     console.log(model, state, "MODEL");
                      //     // state.followingsFilter_timestamp_enabled = false;
                      //     //   state.followingsFilter_created_at_enabled = false;
                      //     if (!timestamp_clear || !created_at_clear) {
                      //       state.followingsFilter_timestamp_enabled = false;
                      //       state.followingsFilter_created_at_enabled = false;
                      //       state.followingsFilter_timestamp_items = [];
                      //       state.followingsFilter_created_at_items = [];
                      //       state.followingsFilter_timestamp_clear = true;
                      //       state.followingsFilter_created_at_clear = true;
                      //     } else state.followingsFilter_global_items = model.items;
                      //   });
                      // }}
                      // filterModel={{
                      //   items: [
                      //     ...timestampFilterModelItems(),
                      //     ...created_atFilterModelItems(),
                      //     ...global_items,
                      //   ],
                      // }}
                      // onColumnVisibilityModelChange={(model) => {
                      //   store.update((state) => {
                      //     state.monitioringColumns = model;
                      //     setLocalStorage("monitioringColumns", model);
                      //   });
                      // }}
                      // columnVisibilityModel={monitioringColumns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "timestamp", sort: "desc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <hr mb={4}></hr>
            <Box
              display="flex"
              justifyContent=""
              alignItems="center"
              mb={2}
              mt={2}
            >
              <Typography
                textTransform={"uppercase"}
                fontWeight={600}
                variant="h4"
              >
                Team Member Analytics
              </Typography>
              <Box ml={2} width={200}>
                <FormControl fullWidth>
                  <InputLabel
                    style={{
                      fontSize: 18,
                    }}
                    id="demo-simple-select-label"
                  >
                    Select Team Member
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={member}
                    style={{
                      border: "1px solid white",
                      fontSize: 18,
                    }}
                    label="Member"
                    onChange={(event) => setMember(event.target.value)}
                  >
                    {mList?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {console.log(reactions, "REACTIONS")}
            {member && (
              <>
                <Grid width={"100%"} container spacing={2}>
                  {/* <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gridAutoRows="140px"
                  gap="20px"
                > */}
                  {/* ROW 1 */}
                  <Grid item xs={12} lg={4}>
                    <Box
                      paddingY={3}
                      gridColumn="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatBox
                        title={
                          reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "YES"
                          )?.length +
                          details?.filter((react) => react.author == member)
                            ?.length
                        }
                        subtitle="Projects Voted Yes"
                        progress={
                          (reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "YES"
                          )?.length +
                            details?.filter((react) => react.author == member)
                              ?.length) /
                          details?.length
                        }
                        increase=""
                        desc={`= ${(
                          ((reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "YES"
                          )?.length +
                            details?.filter((react) => react.author == member)
                              ?.length) /
                            details?.length) *
                          100
                        )?.toFixed()}% of Total Projects`}
                        icon={
                          <CheckCircleIcon
                            sx={{
                              color: colors.greenAccent[600],
                              fontSize: "26px",
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box
                      paddingY={3}
                      gridColumn="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatBox
                        title={
                          reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "NO"
                          )?.length
                        }
                        subtitle="Projects Voted No"
                        progress={
                          reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "NO"
                          )?.length / details?.length
                        }
                        increase=""
                        desc={`= ${(
                          (reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "NO"
                          )?.length /
                            details?.length) *
                          100
                        )?.toFixed()}% of Total Projects`}
                        icon={
                          <CancelIcon
                            sx={{
                              color: colors.greenAccent[600],
                              fontSize: "26px",
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box
                      paddingY={3}
                      gridColumn="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatBox
                        title={
                          details?.length -
                          details?.filter((react) => react.author == member)
                            ?.length -
                          reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "YES"
                          )?.length -
                          reactions?.filter(
                            (react) =>
                              react.name == member && react.vote === "NO"
                          )?.length
                        }
                        subtitle="Projects not Voted "
                        progress={
                          (details?.length -
                            details?.filter((react) => react.author == member)
                              ?.length -
                            reactions?.filter(
                              (react) =>
                                react.name == member && react.vote === "YES"
                            )?.length -
                            reactions?.filter(
                              (react) =>
                                react.name == member && react.vote === "NO"
                            )?.length) /
                          details?.length
                        }
                        increase=""
                        desc={`= ${(
                          ((details?.length -
                            details?.filter((react) => react.author == member)
                              ?.length -
                            reactions?.filter(
                              (react) =>
                                react.name == member && react.vote === "YES"
                            )?.length -
                            reactions?.filter(
                              (react) =>
                                react.name == member && react.vote === "NO"
                            )?.length) /
                            details?.length) *
                          100
                        )?.toFixed()}% of Total Projects`}
                        icon={
                          <SearchOffIcon
                            sx={{
                              color: colors.greenAccent[600],
                              fontSize: "26px",
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Box
                      paddingY={3}
                      gridColumn="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatBox
                        title={
                          details?.filter((react) => react.author == member)
                            ?.length
                        }
                        subtitle="Projects posted "
                        progress={
                          details?.filter((react) => react.author == member)
                            ?.length / details?.length
                        }
                        increase=""
                        desc={`= ${(
                          (details?.filter((react) => react.author == member)
                            ?.length /
                            details?.length) *
                          100
                        )?.toFixed()}% of Total Projects were posted by ${member}`}
                        icon={
                          <InputIcon
                            sx={{
                              color: colors.greenAccent[600],
                              fontSize: "26px",
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Box
                      paddingY={3}
                      gridColumn="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatBox
                        title={
                          details?.filter(
                            (react) =>
                              react.author == member && react.voting == "YES"
                          )?.length
                        }
                        subtitle="Projects upvoted "
                        progress={
                          details?.filter(
                            (react) =>
                              react.author == member && react.voting == "YES"
                          )?.length /
                          details?.filter((react) => react.author == member)
                            ?.length
                        }
                        increase=""
                        desc={`= ${(
                          (details?.filter(
                            (react) =>
                              react.author == member && react.voting == "YES"
                          )?.length /
                            details?.filter((react) => react.author == member)
                              ?.length) *
                          100
                        ).toFixed()}% of Projects Posted by ${member}`}
                        icon={
                          <ThumbUpIcon
                            sx={{
                              color: colors.greenAccent[600],
                              fontSize: "26px",
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Box
                      paddingY={3}
                      gridColumn="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatBox
                        title={
                          details?.filter(
                            (react) =>
                              react.author == member && react.voting == "NO"
                          )?.length
                        }
                        subtitle="Projects down voted "
                        progress={
                          details?.filter(
                            (react) =>
                              react.author == member && react.voting == "NO"
                          )?.length /
                          details?.filter((react) => react.author == member)
                            ?.length
                        }
                        increase=""
                        desc={`= ${(
                          (details?.filter(
                            (react) =>
                              react.author == member && react.voting == "NO"
                          )?.length /
                            details?.filter((react) => react.author == member)
                              ?.length) *
                          100
                        ).toFixed()}% of Projects Posted by ${member}`}
                        icon={
                          <ThumbDown
                            sx={{
                              color: colors.greenAccent[600],
                              fontSize: "26px",
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Box
                      paddingY={3}
                      gridColumn="span 4"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatBox
                        title={
                          details?.filter(
                            (react) =>
                              react.author == member && react.call == "YES"
                          )?.length
                        }
                        subtitle="Projects Call "
                        progress={
                          details?.filter(
                            (react) =>
                              react.author == member && react.call == "YES"
                          )?.length /
                          details?.filter(
                            (react) =>
                              react.author == member && react.voting == "YES"
                          )?.length
                        }
                        increase=""
                        desc={`= ${(
                          (details?.filter(
                            (react) =>
                              react.author == member && react.call == "YES"
                          )?.length /
                            details?.filter(
                              (react) =>
                                react.author == member && react.voting == "YES"
                            )?.length) *
                          100
                        )?.toFixed()}% of Upvoted projects by ${member}`}
                        icon={
                          <ContactPhoneIcon
                            sx={{
                              color: colors.greenAccent[600],
                              fontSize: "26px",
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Typography
                  textTransform={"uppercase"}
                  fontWeight={600}
                  variant="h6"
                  mt={1}
                  mb={-2}
                >
                  Projects posted by {member} in each month
                </Typography>
                <GRAPH member={member} />
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  mt={2}
                >
                  <Typography
                    textTransform={"uppercase"}
                    fontWeight={600}
                    variant="h6"
                  >
                    Select date range for detailed analysis
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 12,
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        className="date"
                        value={subStartDate}
                        onChange={(newValue) => {
                          setSubStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        className="date"
                        value={subEndDate}
                        onChange={(newValue) => {
                          setSubEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <button className="apply_filter_btn" onClick={onSubApply}>
                      Apply
                    </button>
                    <span
                      className="clear_filter"
                      onClick={() => {
                        onSubApply({ clear: true });
                      }}
                    >
                      <HighlightOffIcon />
                    </span>
                  </div>
                </Box> */}
                {/* {(subStartDate || subEndDate) && subDetails && ( */}
                {details && (
                  <>
                    <Box
                      className="container_flex"
                      gap={2}
                      justifyContent={"space-between"}
                    >
                      <Box className="container_flex" gap={2}>
                        {filterList?.map((item, index) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 4,
                            }}
                          >
                            <Button
                              onClick={() => {
                                setFilterModel(item);
                              }}
                              variant={
                                JSON.stringify(item) ===
                                JSON.stringify(filterModel)
                                  ? "contained"
                                  : "outlined"
                              }
                              color="info"
                            >
                              {item.name}
                            </Button>
                          </div>
                        ))}
                      </Box>
                      <Button
                        onClick={() => {
                          setFilterModel({ items: [] });
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Clear All Filters
                      </Button>
                    </Box>
                    <Box>
                      <Box
                        m="0 0 0 0"
                        height="75vh"
                        sx={{
                          "& .MuiDataGrid-root": {
                            border: "none",
                          },
                          "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                          },
                          "& .name-column--cell": {
                            color: colors.greenAccent[300],
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                          },
                          "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                          },
                          "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                          },
                          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                          },
                        }}
                      >
                        <DataGridPro
                          autoHeight
                          rows={details?.filter(
                            (item) => item?.author === member
                          )}
                          columns={columns}
                          components={{ Toolbar: GridToolbar }}
                          // on row click set the row details in the state in the setModalContent
                          // loading={loading}
                          // onRowsScrollEnd={handleOnRowsScrollEnd}
                          // hideFooterPagination
                          pagination
                          rowsPerPageOptions={[50, 100, 300, 500]}
                          //filterMode={"client"}
                          filterModel={filterModel}
                          onFilterModelChange={(newFilterModel) => {
                            // localStorage.setItem(
                            //   "filterMonitoring",
                            //   JSON.stringify(newFilterModel)
                            // );
                            console.log(newFilterModel);
                            setFilterModel(newFilterModel);
                          }}
                          // onFilterModelChange={(model) => {
                          //   store.update((state) => {
                          //     console.log(model, state, "MODEL");
                          //     // state.followingsFilter_timestamp_enabled = false;
                          //     //   state.followingsFilter_created_at_enabled = false;
                          //     if (!timestamp_clear || !created_at_clear) {
                          //       state.followingsFilter_timestamp_enabled = false;
                          //       state.followingsFilter_created_at_enabled = false;
                          //       state.followingsFilter_timestamp_items = [];
                          //       state.followingsFilter_created_at_items = [];
                          //       state.followingsFilter_timestamp_clear = true;
                          //       state.followingsFilter_created_at_clear = true;
                          //     } else state.followingsFilter_global_items = model.items;
                          //   });
                          // }}
                          // filterModel={{
                          //   items: [
                          //     ...timestampFilterModelItems(),
                          //     ...created_atFilterModelItems(),
                          //     ...global_items,
                          //   ],
                          // }}
                          // onColumnVisibilityModelChange={(model) => {
                          //   store.update((state) => {
                          //     state.monitioringColumns = model;
                          //     setLocalStorage("monitioringColumns", model);
                          //   });
                          // }}
                          // columnVisibilityModel={monitioringColumns}
                          initialState={{
                            sorting: {
                              sortModel: [{ field: "timestamp", sort: "desc" }],
                            },
                          }}
                          componentsProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </main>
      </div>
    );
  }
};

export default Analytics;
