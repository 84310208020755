import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useEffect, useState } from "react";
import { db } from "../../utils/init-firebase";
import { collection, getDocs, query } from "firebase/firestore";
import Checkbox from "@mui/material/Checkbox";
import { store } from "../../store/store";
import {
  removeLocalStorage,
  setLocalStorage,
} from "../../helpers/localStorage";
import { Box } from "@mui/material";
import Header from "../../components/Header";

const Settings = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const q = query(collection(db, "RTEAM"));
    const querySnapshot = await getDocs(q);
    // set both doc data and id to userList
    setUserList(
      querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box ml="20px" className="container_flex">
          <Header
            className="header_flex"
            title="GLOBAL SETTINGS"
            subtitle="Save the user list whose data needs to be shown."
          />
        </Box>
        <Box ml="20px">
          {userList.map((user, index) => (
            <UserCheckbox
              key={index}
              id={user.id}
              fname={user.fname}
              lname={user.lname}
            />
          ))}
        </Box>
      </main>
    </div>
  );
};

const UserCheckbox = ({ fname, lname, id }) => {
  const rteamIds = store.useState((s) => s.rteamIds);

  return (
    <div>
      <Checkbox
        color={"secondary"}
        checked={rteamIds.includes(id)}
        onChange={(value) => {
          if (value.target.checked) {
            store.update((s) => {
              s.rteamIds.push(id);
              setLocalStorage("rteamIds", s.rteamIds);
            });
          } else {
            store.update((s) => {
              s.rteamIds = s.rteamIds.filter((rteamId) => rteamId !== id);
              setLocalStorage("rteamIds", s.rteamIds);
            });
          }
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
      <span>{fname + " " + lname}</span>
    </div>
  );
};

export default Settings;
