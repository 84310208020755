import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Analytics from "./scenes/analytics";
import Followings from "./scenes/followings";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Login from "./scenes/Login/Login";
// import Signup from "./scenes/Signup/Signup";
import AuthContextProvider, { useAuth } from "./contexts/AuthContext";
import Handles from "./scenes/handles";
import Tweets from "./scenes/tweets";
import Profile from "./scenes/profile";

import { LicenseInfo } from "@mui/x-license-pro";
import Notifications from "./scenes/notifications";
import AddUser from "./scenes/add-user/AddUser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Filtering from "./scenes/filtering/Filtering";
import Settings from "./scenes/settings";
import { getLocalStorage } from "./helpers/localStorage";
import Monitoring from "./scenes/monitoring";
import ReleaseForm from "./scenes/release-form/ReleaseForm";
import Releases from "./scenes/releases/Releases";
import AddGithubUser from "./scenes/add-github-user/AddGithubUser";
import AddGithubRepo from "./scenes/add-github-repo/AddGithubRepo";
import RepoList from "./scenes/Github/RepoList";
import RepoForks from "./scenes/Github/RepoForks";
import UserList from "./scenes/Github/UserList";
import UserStars from "./scenes/Github/UserStars";

LicenseInfo.setLicenseKey(
  "69a794da942687499bce62417d717a5aTz01NDk4NSxFPTE3MDA5NDAwNDEzNTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const [theme, colorMode] = useMode();

  return (
    <AuthContextProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <ToastContainer />
            <Switch>
              <Route exact path="/login" component={Login} />
              {/* <Route exact path="/signup" component={Signup} /> */}
              <ProtectedRoute exact path="/" component={Followings} />
              <ProtectedRoute exact path="/monitoring" component={Monitoring} />
              <ProtectedRoute exact path="/handles" component={Handles} />
              <ProtectedRoute exact path="/github-repo-list" component={RepoList} />
              <ProtectedRoute exact path="/github-repo-forks" component={RepoForks} />
              <ProtectedRoute exact path="/github-user-list" component={UserList} />
              <ProtectedRoute exact path="/github-user-stars" component={UserStars} />
              <ProtectedRoute exact path="/analytics" component={Dashboard} />
              <ProtectedRoute exact path="/monitoring-analytics" component={Analytics} />
              <ProtectedRoute exact path="/tweets" component={Tweets} />
              <ProtectedRoute exact path={"/settings"} component={Settings} />
              <ProtectedRoute
                exact
                path="/notifications"
                component={Notifications}
              />
              <ProtectedRoute exact path="/profile" component={Profile} />
              <ProtectedRoute exact path="/add-handles" component={AddUser} />
              <ProtectedRoute exact path="/add-github-handles" component={AddGithubUser} />
              <ProtectedRoute exact path="/add-github-repo" component={AddGithubRepo} />
              <ProtectedRoute exact path="/releases" component={Releases} />
              <ProtectedRoute exact path="/dev-release" component={ReleaseForm} />
              <ProtectedRoute exact path="/filtering" component={Filtering} />
            </Switch>
          </Router>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthContextProvider>
  );
}

function ProtectedRoute(props) {
  const { currentUser } = useAuth();
  const { path } = props;
  // console.log("path", path);
  const location = useLocation();
  // console.log("location state", location.state);

  if (
    path === "/login" ||
    path === "/signup" ||
    path === "/forgot-password" ||
    path === "/reset-password"
  ) {
    return currentUser ? (
      <Redirect to={location.state?.from ?? "/"} />
    ) : (
      <Route {...props} />
    );
  }
  return getLocalStorage("currentUser") ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: path },
      }}
    />
  );
}
export default App;
