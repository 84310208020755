import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import Avatar from "@mui/material/Avatar";
import "../scenes.css";
import BasicDateRangePicker from "../unix";
import LoadingGif from "../../assets/loading.gif";
import { store } from "../../store/store";
import { setLocalStorage } from "../../helpers/localStorage";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

const Notifications = () => {
  const timestamp_items = store.useState(
    (s) => s.followingsFilter_timestamp_items
  );
  const timestamp_enabled = store.useState(
    (s) => s.followingsFilter_timestamp_enabled
  );
  const created_at_items = store.useState(
    (s) => s.followingsFilter_created_at_items
  );
  const created_at_enabled = store.useState(
    (s) => s.followingsFilter_created_at_enabled
  );
  const global_items = store.useState((s) => s.followingsFilter_global_items);
  const timestamp_clear = store.useState(
    (s) => s.followingsFilter_timestamp_clear
  );
  const created_at_clear = store.useState(
    (s) => s.followingsFilter_created_at_clear
  );
  const lowerTimestampLimit = store.useState((s) => s.lowerTimestampLimit);
  const upperTimestampLimit = store.useState((s) => s.upperTimestampLimit);
  const rteamIds = store.useState((s) => s.rteamIds);
  const notificationsColumns = store.useState((s) => s.notificationsColumns);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [following, setFollowing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState("");
  const [toastShown, setToastShown] = useState(false);
  const [lastVisited, setLastVisited] = useState(createUnixTS());
  const { currentUser } = useAuth();
  const data = [];

  useEffect(() => {
    getData();
    updateLastVisitedNotif();
  }, []);

  function createUnixTS() {
    const date = new Date();
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  const updateLastVisitedNotif = async (e) => {
    try {
      await updateDoc(doc(db, "RTEAM", currentUser.uid), {
        last_visited: createUnixTS(),
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [!loading, upperTimestampLimit, lowerTimestampLimit, rteamIds]);

  function unixToReadable(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    if (month < 10) {
      month = "0" + month.toString();
    }
    var date = a.getDate();
    if (date < 10) {
      date = "0" + date.toString();
    }
    var hour = a.getHours();
    if (hour < 10) {
      hour = "0" + hour.toString();
    }
    var min = a.getMinutes();
    if (min < 10) {
      min = "0" + min.toString();
    }
    var sec = a.getSeconds();
    if (sec < 10) {
      sec = "0" + sec.toString();
    }
    var time =
      month + "/" + date + "/" + year + ", " + hour + ":" + min + ":" + sec;
    return time;
  }

  // function getFollowings() {
  //   const followingCollectionRef = collection(db, "NOTIFICATIONS");
  //   getDocs(followingCollectionRef)
  //     .then((response) => {
  //       const follow = response.docs.map((doc) => ({
  //         data: doc.data(),
  //         id: doc.id,
  //       }));
  //       // console.log(follow);
  //       for (let i = 0; i < follow.length; i++) {
  //         const data_object = {};
  //         data_object.pfp = { profile_image_url: "", username: "" };
  //         data_object.pfpuser = { profile_image_url: "", username: "" };
  //         data_object.pfp.profile_image_url =
  //           "https://w7.pngwing.com/pngs/537/580/png-transparent-bell-notification-communication-information-icon.png";
  //         data_object.pfpuser.profile_image_url =
  //           "https://www.freeiconspng.com/thumbs/person-icon-blue/person-icon-blue-18.png";
  //         data_object.pfp.username = follow[i].data.following;
  //         data_object.pfpuser.username = follow[i].data.username;
  //         data_object.username = follow[i].data.username;
  //         data_object.following = follow[i].data.following;
  //         data_object.id = follow[i].id;
  //         data_object.timestamp = follow[i].data.timestamp;
  //         data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
  //         data.push(data_object);
  //       }

  //       setIsLoading(false);
  //       setFollowing(data);
  //     })
  //     .catch((error) => console.log(error.message));
  // }

  async function getData() {
    setLoading(true);

    if (lastVisible === "") {
      console.log("Inside if");
      setToastShown(false);
      const first = query(
        collection(db, "NOTIFICATIONS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        where("added_by", "in", rteamIds),
        limit(500)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];

      setLastVisible(last);

      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "" };
        data_object.pfpuser = { profile_image_url: "", username: "" };
        data_object.pfp.profile_image_url =
          "https://w7.pngwing.com/pngs/537/580/png-transparent-bell-notification-communication-information-icon.png";
        data_object.pfpuser.profile_image_url =
          "https://www.freeiconspng.com/thumbs/person-icon-blue/person-icon-blue-18.png";
        data_object.pfp.username = follow[i].data.following;
        data_object.pfpuser.username = follow[i].data.username;
        data_object.username = follow[i].data.username;
        data_object.following = follow[i].data.following;
        data_object.id = follow[i].id;
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    } else {
      console.log("Inside else");

      if (!lastVisible && !toastShown) {
        toast.success("Data loaded successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToastShown(true);
      }

      const first = query(
        collection(db, "NOTIFICATIONS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        where("added_by", "in", rteamIds),
        startAfter(lastVisible),
        limit(500)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];

      setLastVisible(last);

      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "" };
        data_object.pfpuser = { profile_image_url: "", username: "" };
        data_object.pfp.profile_image_url =
          "https://w7.pngwing.com/pngs/537/580/png-transparent-bell-notification-communication-information-icon.png";
        data_object.pfpuser.profile_image_url =
          "https://www.freeiconspng.com/thumbs/person-icon-blue/person-icon-blue-18.png";
        data_object.pfp.username = follow[i].data.following;
        data_object.pfpuser.username = follow[i].data.username;
        data_object.username = follow[i].data.username;
        data_object.following = follow[i].data.following;
        data_object.id = follow[i].id;
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    }
  }

  const columns = [
    {
      field: "pfp",
      headerName: "GoTo New",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/${params.value.username}`}
            >
              <Avatar src={params.value.profile_image_url} />
            </a>
          </>
        );
      },
    },
    {
      field: "following",
      headerName: "New Addition",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "pfpuser",
      headerName: "GoTo User",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/${params.value.username}`}
            >
              <Avatar src={params.value.profile_image_url} />
            </a>
          </>
        );
      },
    },

    {
      field: "username",
      headerName: "Following of",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "timestamp_utc",
      headerName: "Timestamp (Local)",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "timestamp",
      headerName: "Timestamp (UNIX)",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
  ];
  const timestampFilterModelItems = () => {
    return timestamp_enabled ? timestamp_items : [];
  };

  const created_atFilterModelItems = () => {
    return created_at_enabled ? created_at_items : [];
  };
  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="NOTIFICATIONS"
              subtitle="List of all recent followings of the twitter handles."
            />
            <img className="loading_image" src={LoadingGif} alt="loading" />
            <p className="loading_text">
              Please be Patient! Fetching all data!
            </p>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px" className="container_flex">
            <Header
              className="header_flex"
              title="NOTIFICATIONS"
              subtitle="List of all recent followings of the twitter handles."
            />
            <BasicDateRangePicker
              unix={"timestamp"}
              className="date_flex"
              followingCallback={setFollowing}
              lastVisibleCallback={setLastVisible}
            />
          </Box>
          <Box m="20px">
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGridPro
                autoHeight
                rows={following}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                pagination
                rowsPerPageOptions={[50, 100, 300, 500]}
                //filterMode={"client"}
                onFilterModelChange={(model) => {
                  store.update((state) => {
                    // state.followingsFilter_timestamp_enabled = false;
                    //   state.followingsFilter_created_at_enabled = false;
                    if (!timestamp_clear || !created_at_clear) {
                      state.followingsFilter_timestamp_enabled = false;
                      state.followingsFilter_created_at_enabled = false;
                      state.followingsFilter_timestamp_items = [];
                      state.followingsFilter_created_at_items = [];
                      state.followingsFilter_timestamp_clear = true;
                      state.followingsFilter_created_at_clear = true;
                    } else state.followingsFilter_global_items = model.items;
                  });
                }}
                filterModel={{
                  items: [
                    ...timestampFilterModelItems(),
                    ...created_atFilterModelItems(),
                    ...global_items,
                  ],
                }}
                onColumnVisibilityModelChange={(model) => {
                  store.update((state) => {
                    state.notificationsColumns = model;
                    setLocalStorage("notificationsColumns", model);
                  });
                }}
                columnVisibilityModel={notificationsColumns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </main>
      </div>
    );
  }
};

export default Notifications;
