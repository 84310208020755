export const teamId = {
  Metals: "Metals",
  metals: "Metals",
  ".greenchile": "greenchile",
  greenchile: "greenchile",
  gauravraj0510: "Raj510",
  Raj510: "Raj510",
  Dilyan: "Dilyan",
  dilyan88: "Dilyan",
  _linux: "Linux",
  Linux: "Linux",
  "Supercounter🦝": "Supercounter🦝",
  supercounter: "Supercounter🦝",
  "sheihahaha | Unique.vc": "sheihahaha | Unique.vc",
  sheihahaha: "sheihahaha | Unique.vc",
  yodam: "yodam",
  twizo: "twizo",
  bonhanza: "bonhanza",
  Bonanza: "bonhanza",
  ScruFFuR: "ScruFFuR",
  scruffur: "ScruFFuR",
  db7981: "db7981",
  pearon_: "pearon_",
  "RTP Monitoring Bot": "RTP Monitoring Bot",
};
