import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Sidebar from "../../scenes/global/Sidebar";
import Topbar from "../../scenes/global/Topbar";
import React, { useState } from "react";
import axios from "axios";
import { addDoc, collection, setDoc, doc } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

const AddUser = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isSidebar, setIsSidebar] = useState(true);
  const { currentUser } = useAuth();
  const [form, setForm] = useState({
    username: "",
    company: "",
    comments: "",
  });
  const [data, setData] = useState({});

  const [error, setError] = useState({});

  function createUnixTS() {
    const date = new Date();
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  const handleInput = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    console.log(id, value);
    if (error[id]) {
      setError({ ...error, [id]: "" });
    }
    setForm({ ...form, [id]: value });
  };

  const getData = () => {};

  const validateForm = () => {
    const errors = {};
    if (form.username === "") {
      errors.username = "Username is required";
    }
    // if (form.company === "") {
    //   errors.company = "Company is required";
    // }
    // if (form.comments === "") {
    //   errors.comments = "Comments are required";
    // }
    setError(errors);
    return errors;
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const valid = validateForm();
    console.log(valid);
    if (Object.keys(valid).length !== 0) {
      return;
    }
    const response = await axios.get(
      "https://rt-portal-backend.vercel.app/tweet",
      {
        params: {
          username: form.username,
        },
      }
    );
    setData(response.data.data[0]);

    setDoc(doc(db, "USERS", form.username), {
      username: response.data.data[0].username,
      created_at: response.data.data[0].created_at,
      description: response.data.data[0].description,
      // entities: response.data.data[0].entities,
      followings: [],
      tweets: [],
      id: response.data.data[0].id,
      name: response.data.data[0].name,
      profile_image_url: response.data.data[0].profile_image_url,
      public_metrics: response.data.data[0].public_metrics,
      // url: response.data.data[0].url,
      timestamp: createUnixTS(),
      company: form.company,
      comments: form.comments,
      added_by: currentUser.uid,
    })
      .then((docRef) => {
        toast.success("User added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setTimeout(5000);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        toast.error("Error adding user");
      })
      .finally(() => {
        setForm({
          username: "",
          company: "",
          comments: "",
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      });
  };

  const initialValues = {
    username: "username",
    company: "company",
    comments: "comments",
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <div>
          <Box m="20px">
            <Header
              title="Add Twitter Handle"
              subtitle="Add twitter handles to track their followings."
            />

            <Formik
              onSubmit={handleAdd}
              initialValues={initialValues}
              // validationSchema={checkoutSchema}
            >
              {({
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleAdd}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Twitter Username"
                      onBlur={handleBlur}
                      onChange={handleInput}
                      value={form.username}
                      name="username"
                      error={error?.username}
                      helperText={touched.username && errors.username}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Company Name"
                      onBlur={handleBlur}
                      onChange={handleInput}
                      value={form.company}
                      name="company"
                      error={!!touched.company && !!errors.company}
                      helperText={touched.company && errors.company}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Comments"
                      onBlur={handleBlur}
                      onChange={handleInput}
                      value={form.comments}
                      name="comments"
                      error={!!touched.comments && !!errors.comments}
                      helperText={touched.comments && errors.comments}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="start" mt="20px">
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      // sx={{ width: "320px" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          {console.log(data)}
          {Object.keys(data).length != 0 && (
            <Box m="20px">
              <Header title="Added Twitter Handle Details" subtitle="" />
              <p>
                Name: <b className="profile_details">{data?.name}</b>
              </p>
              <p>
                Username: <b className="profile_details">{data?.username}</b>
              </p>
              <p>
                Description:{" "}
                <b className="profile_details">{data?.description}</b>
              </p>
              <p>
                Followers:{" "}
                <b className="profile_details">
                  {data?.public_metrics.followers_count}
                </b>
              </p>
            </Box>
          )}
        </div>
      </main>
    </div>
  );
};

// const checkoutSchema = yup.object().shape({
//   fname: yup.string().required("required"),
//   lname: yup.string().required("required"),
//   email: yup.string().required("required"),
//   role: yup.string().required("required"),
// });

export default AddUser;
