import { Box, Button, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import { Diversity3, PeopleAlt, RecentActors } from "@mui/icons-material";
import { db } from "../../utils/init-firebase";
import { collection, getDocs } from "firebase/firestore";

let users_count = 0;
let following_count = 0;
let tweet_count = 0;
let graphData = {};

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    graphData = {};
    const usersCollectionRef = collection(db, "USERS");
    await getDocs(usersCollectionRef)
      .then((response) => {
        const d = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        users_count = d.length;
        tweet_count = 0;
        following_count = 0;
        for (let i = 0; i < d.length; i++) {
          let twt_count = d[i].data.tweets.length;
          {
            tweet_count = tweet_count + twt_count;
          }
          let flwng_count = d[i].data.followings.length;
          {
            following_count = following_count + flwng_count;
          }
        }
        setIsLoading(false);
        graphData = {
          user: users_count,
          tweet: tweet_count / users_count,
          follow: following_count / users_count,
        };
      })
      .catch((error) => console.log(error.message));
  }

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header
              title="ANALYTICS"
              subtitle="Welcome to your analytics dashboard"
            />

            <Box></Box>
          </Box>

          {/* GRID & CHARTS */}
          <Grid container spacing={3}>
            {/* <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          > */}
            {/* ROW 1 */}

            <Grid item xs={12} lg={3}>
              <Box
                gridColumn="span 3"
                paddingY={3}
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={users_count}
                  subtitle="Twitter Handles"
                  progress="0.75"
                  increase=""
                  icon={
                    <PeopleAlt
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                gridColumn="span 3"
                paddingY={3}
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={tweet_count}
                  subtitle="Total Tweet Count"
                  progress="0.50"
                  increase=""
                  icon={
                    <RecentActors
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                paddingY={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={following_count}
                  subtitle="Total Followings"
                  progress="0.30"
                  increase=""
                  icon={
                    <Diversity3
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                gridColumn="span 3"
                paddingY={3}
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title="0"
                  subtitle="Approved Projects"
                  progress="0.5"
                  increase=""
                  icon={
                    <ThumbUpIcon
                      sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            {/* ROW 2 */}
            {/* <Box
              gridColumn="span 8"
              paddingY={3}
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
            >
              <Box
                mt="25px"
                p="0 30px"
                display="flex "
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Revenue Generated
                  </Typography>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={colors.greenAccent[500]}
                  >
                    $59,342.32
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box height="250px" m="-20px 0 0 0">
                <LineChart isDashboard={true} />
              </Box>
            </Box>
            <Box
              gridColumn="span 4"
              paddingY={3}
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[100]}
                p="15px"
              >
                <Typography
                  color={colors.grey[100]}
                  variant="h5"
                  fontWeight="600"
                >
                  Recent Transactions
                </Typography>
              </Box>
              {mockTransactions.map((transaction, i) => (
                <Box
                  key={`${transaction.txId}-${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p="15px"
                >
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant="h5"
                      fontWeight="600"
                    >
                      {transaction.txId}
                    </Typography>
                    <Typography color={colors.grey[100]}>
                      {transaction.user}
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>{transaction.date}</Box>
                  <Box
                    backgroundColor={colors.greenAccent[500]}
                    p="5px 10px"
                    borderRadius="4px"
                  >
                    ${transaction.cost}
                  </Box>
                </Box>
              ))}
            </Box> */}

            {/* ROW 3 */}

            <Grid item xs={12} lg={4}>
              <Box
                gridColumn="span 4"
                paddingY={3}
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
                p="30px"
              >
                <Typography variant="h5" fontWeight="600">
                  User Data
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt="25px"
                >
                  <ProgressCircle size="125" />
                  <Typography
                    variant="h5"
                    color={colors.greenAccent[500]}
                    sx={{ mt: "15px" }}
                  >
                    {users_count} twitter handles.
                  </Typography>
                  <Typography>Depicts unique twitter handles.</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                gridColumn="span 4"
                paddingY={3}
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  sx={{ padding: "30px 30px 0 30px" }}
                >
                  Twitter User Growth
                </Typography>
                <Box height="250px" mt="-20px">
                  <BarChart isDashboard={true} />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Box
                gridColumn="span 4"
                paddingY={3}
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
                padding="30px"
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  sx={{ marginBottom: "15px" }}
                >
                  Geography Based Traffic
                </Typography>
                <Box height="200px">
                  <GeographyChart isDashboard={true} />
                </Box>
              </Box>
            </Grid>
            {/* Row 3 End */}
          </Grid>
        </Box>
      </main>
    </div>
  );
};

export default Dashboard;
