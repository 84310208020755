import { Box, Button, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import React, { useState } from "react";
import img1 from "../../assets/filtering1.png";
import img2 from "../../assets/filtering2.png";
import img3 from "../../assets/filtering3.png";
import img4 from "../../assets/filtering4.png";
const Filtering = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <div>
          <Box m="20px" pb="50px">
            <Header
              title="Filters for String Data"
              subtitle="Filter operations for string data."
            />
            <ol>
              <li>
                <b className="profile_details">contains : </b>Checks for all
                entries with the entered filter value, can be in between, start
                or end.
              </li>
              <li>
                <b className="profile_details">equals :</b> Finds an exact match{" "}
              </li>
              <li>
                <b className="profile_details">starts with :</b> Gets all
                entries which start with the filter value.
              </li>
              <li>
                <b className="profile_details">ends with :</b> Gets all entries
                which end with the filter value.
              </li>
              <li>
                <b className="profile_details">is empty :</b> Gets all entries
                whose data is empty
              </li>
              <li>
                <b className="profile_details">is not empty :</b> Gets all
                entries whose data is not empty (reduces noise)
              </li>
              <li>
                <b className="profile_details">is any of :</b> Provide a list of
                keywords to get a match.
              </li>
            </ol>
            <img src={img1} alt="" />
          </Box>
          <Box m="20px" pb="50px">
            <Header
              title="Filters for Numeric Data"
              subtitle="Filter operations for numeric data."
            />
            <ol>
              <li>
                <b className="profile_details">{"= : "}</b>Gives exact match
              </li>
              <li>
                <b className="profile_details">{"!= :"}</b> Gives all entries
                which are not equal to the filter value.
              </li>
              <li>
                <b className="profile_details">{"> :"}</b> Gives all data which
                is greater than the filter value.
              </li>
              <li>
                <b className="profile_details">{">= "}</b> Gives all data which
                is greater than and equal to the filter value.
              </li>
              <li>
                <b className="profile_details">{"< :"}</b> Gives all data which
                is lesser than the filter value.
              </li>
              <li>
                <b className="profile_details">{"<= :"}</b> Gives all data which
                is lesser than and equal to the filter value.
              </li>
            </ol>
            <img src={img2} alt="" />
          </Box>
          <Box m="20px" pb="50px">
            <Header
              title="Using Date Time to UNIX Tool"
              subtitle="Getting data over a time period using UNIX timestamp."
            />
            <img src={img3} alt="" />
            <p>
              The above tool gives a UNIX Timestamp range for filtering data
              based on time. For example: UNIX Range for 4th November to 12th
              December is
            </p>
            <b className="profile_details">
              {"1667501269 < UNIX Time < 1670093269 "}
            </b>
            <p>
              These values are used to filter data in the various tables. These
              values will be auto applied on selecting a date range. <br />
              For example:
              <br />
              <img src={img4} alt="" />
            </p>
          </Box>
          <Box m="20px" pb="50px">
            <Header
              title="More about UNIX timestamps."
              subtitle="Official description of UNIX."
            />
            <ul>
              <li>
                The unix timestamp is a way to track time as a running total of
                seconds. This count starts at the Unix Epoch on January 1st,
                1970 at UTC. Therefore, the unix time stamp is merely the number
                of seconds between a particular date and the Unix Epoch. It
                should also be pointed out (thanks to the comments from visitors
                to this site) that this point in time technically does not
                change no matter where you are located on the globe. This is
                very useful to computer systems for tracking and sorting dated
                information in dynamic and distributed applications both online
                and client side.
              </li>
              <li>
                <a
                  className="profile_details"
                  href="https://www.unixtimestamp.com/"
                >
                  https://www.unixtimestamp.com/
                </a>
              </li>
            </ul>
          </Box>
        </div>
      </main>
    </div>
  );
};

export default Filtering;
