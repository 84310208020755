import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  startAfter,
  limit,
  orderBy,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import Avatar from "@mui/material/Avatar";
import "../scenes.css";
import LoadingGif from "../../assets/loading.gif";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import BasicDateRangePicker from "../unix";
import LinearProgress from "@mui/material/LinearProgress";
import { store } from "../../store/store";
import { toast } from "react-toastify";
import { Modal } from "../../components/Modal/Modal";
import { setLocalStorage } from "../../helpers/localStorage";

const RepoForks = () => {
  const timestamp_items = store.useState(
    (s) => s.followingsFilter_timestamp_items
  );
  const timestamp_enabled = store.useState(
    (s) => s.followingsFilter_timestamp_enabled
  );
  const created_at_items = store.useState(
    (s) => s.followingsFilter_created_at_items
  );
  const created_at_enabled = store.useState(
    (s) => s.followingsFilter_created_at_enabled
  );
  const global_items = store.useState((s) => s.followingsFilter_global_items);
  const timestamp_clear = store.useState(
    (s) => s.followingsFilter_timestamp_clear
  );
  const created_at_clear = store.useState(
    (s) => s.followingsFilter_created_at_clear
  );
  const upperTimestampLimit = store.useState((s) => s.upperTimestampLimit);
  const lowerTimestampLimit = store.useState((s) => s.lowerTimestampLimit);
  const rteamIds = store.useState((s) => s.rteamIds);
  const repoForksColumns = store.useState((s) => s.repoForksColumns);
  // split the rteamIds into batches of 10
  const rteamIdsBatches = [];
  for (let i = 0; i < rteamIds.length; i += 10) {
    rteamIdsBatches.push(rteamIds.slice(i, i + 10));
  }
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [following, setFollowing] = useState(false);
  const [lastVisible, setLastVisible] = useState("");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [toastShown, setToastShown] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [fields, setFields] = useState({
    company: "",
    comments: "",
  });
  const [filterModel, setFilterModel] = useState(
    JSON.parse(localStorage.getItem("filterRepoForkList")) || { items: [] }
  );
  const [localList, setLocalList] = useState([]);

  useEffect(() => {
    const newList = JSON.parse(localStorage.getItem("handleRepoForkList"));
    setLocalList(newList);
  }, [localStorage.getItem("handleRepoForkList")]);

  function containsObject(obj, list) {
    return list.find(
      (elem) => JSON.stringify(elem.items) === JSON.stringify(obj.items)
    );
    // return false;
  }

  useEffect(() => {
    console.log(upperTimestampLimit, lowerTimestampLimit);
  }, [!loading, upperTimestampLimit, lowerTimestampLimit, rteamIds]);

  function createUnixTS(dateStr) {
    const date = new Date(dateStr);
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  function unixToReadable(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    if (month < 10) {
      month = "0" + month.toString();
    }
    var date = a.getDate();
    if (date < 10) {
      date = "0" + date.toString();
    }
    var hour = a.getHours();
    if (hour < 10) {
      hour = "0" + hour.toString();
    }
    var min = a.getMinutes();
    if (min < 10) {
      min = "0" + min.toString();
    }
    var sec = a.getSeconds();
    if (sec < 10) {
      sec = "0" + sec.toString();
    }
    var time =
      year + "/" + month + "/" + date + ", " + hour + ":" + min + ":" + sec;
    return time;
  }

  const getData = async () => {
    const docRef = await getDocs(
      query(
        collection(db, "GH_REPO_FORKS"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        where("added_by", "in", rteamIds)
      )
    );

    const data_ = docRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      created_at: unixToReadable(createUnixTS(doc.data().created_at)),
      timestamp: unixToReadable(doc.data().timestamp),
      fork_of: doc.data().fork_of.replace("$#$", "/"),
    }));
    console.log(data_);
    setData(data_);
    setIsLoading(false);
  };

  const HandleUpdate = async (id) => {
    // e.preventDefault();
    if (id) {
      try {
        console.log(modalContent);
        const res = await updateDoc(doc(db, "GH_REPO_FORKS", id), {
          status: 1,
        });
        // toast.success(
        //   `Updated data for ${modalContent?.username} successfully!`
        // );
        await getData();
      } catch (err) {
        console.log(err);
        // toast.error(`Failed to update data for ${modalContent?.username}!`);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [lowerTimestampLimit, upperTimestampLimit]);

  console.log(lowerTimestampLimit, upperTimestampLimit, "TIME");

  const columns = [
    {
      field: "avatar_url",
      headerName: "GoTo",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={`${params.row?.html_url}`}
            >
              <Avatar src={params.value} />
            </a>
          </>
        );
      },
    },
    {
      field: "login",
      headerName: "Username",
      flex: 0.5,
    },
    {
      field: "fork_of",
      headerName: "Fork Of",
      flex: 0.7,
      type: "number",
      headerAlign: "left",
      cellClassName: "name-column--cell",
      align: "left",
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={"https://github.com/" + `${params.value}`}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      field: "forked_repo_url",
      headerName: "GoTo Fork",
      flex: 0.7,
      type: "number",
      headerAlign: "left",
      cellClassName: "name-column--cell",
      align: "left",
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={params.value}
            >
              {params.value.slice(19)}
            </a>
          </>
        );
      },
    },
    {
      field: "twitter_username",
      headerName: "Twitter",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      cellClassName: "name-column--cell",
      align: "left",
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              target="_blank"
              rel="noopener noreferrer"
              href={"https://twitter.com/" + `${params.value}`}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "blog",
      headerName: "Website",
      cellClassName: "name-column--cell",
      flex: 0.5,
      renderCell: (params) => {
        console.log(params.value, "VALUE")
        if (params.value && params.value.split("https://")?.length > 0 ) {
          return (
            <>
              <a
                className="twitter_url"
                target="_blank"
                rel="noopener noreferrer"
                href={`${params.value}`}
              >
                {params.value}
              </a>
            </>
          );
        } else {
          return (
            <>
              <a
                className="twitter_url"
                target="_blank"
                rel="noopener noreferrer"
                href={"https://" + `${params.value}`}
              >
                {params.value}
              </a>
            </>
          );
        }
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      cellClassName: "name-column--cell",
      align: "left",
    },
    // {
    //   field: "company",
    //   headerName: "Company",
    //   flex: 0.3,
    // },

    {
      field: "comments",
      headerName: "Comments",
      flex: 0.3,
    },
    {
      field: "location",
      headerName: "Location",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },
    {
      field: "public_repos",
      headerName: "Repos",
      flex: 0.3,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "public_gists",
      headerName: "Gists",
      flex: 0.3,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "followers",
      headerName: "Followers",
      flex: 0.3,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "following",
      headerName: "Following",
      flex: 0.3,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "created_at",
      headerName: "Creation (YYYY/MM/DD)",
      flex: 0.3,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    // {
    //   field: "timestamp",
    //   headerName: "Timestamp (UNIX)",
    //   flex: 0.3,
    //   type: "number",
    //   headerAlign: "left",
    //   align: "left",
    // },
  ];
  const timestampFilterModelItems = () => {
    return timestamp_enabled ? timestamp_items : [];
  };

  const created_atFilterModelItems = () => {
    return created_at_enabled ? created_at_items : [];
  };
  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="REPOSITORIES' FORKS"
              subtitle="List of all new forks of github repositories being tracked."
            />
            <img className="loading_image" src={LoadingGif} alt="loading" />
            <p className="loading_text">
              Please be Patient! Fetching all data!
            </p>
          </Box>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px" className="container_flex">
            <Header
              className="header_flex"
              title="REPOSITORIES' FORKS"
              subtitle="List of all new forks of github repositories being tracked."
            />
            <BasicDateRangePicker
              unix={"timestamp"}
              className="date_flex"
              is_handle={true}
              followingCallback={setFollowing}
              lastVisibleCallback={setLastVisible}
              type="git"
            />
            <BasicDateRangePicker unix={"created_at"} className="date_flex" />
          </Box>
          <Box m="20px" className="container_flex" gap={2}>
            {localList?.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Button
                  onClick={() => {
                    localStorage.setItem(
                      "filterRepoForkList",
                      JSON.stringify(item)
                    );
                    setFilterModel(item);
                  }}
                  variant="outlined"
                  color="info"
                >
                  Config {index + 1}
                </Button>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const newList = localList.filter(
                      (listt) => JSON.stringify(listt) !== JSON.stringify(item)
                    );
                    localStorage.setItem(
                      "handleRepoForkList",
                      JSON.stringify(newList)
                    );
                    setLocalList(newList);
                  }}
                />
              </div>
            ))}
          </Box>
          <Box
            m="20px"
            className="container_flex"
            justifyContent={"space-between"}
          >
            <Button
              onClick={() => {
                const currentConfig = JSON.parse(
                  localStorage.getItem("filterRepoForkList")
                );
                if (currentConfig?.items?.length > 0) {
                  const list =
                    localStorage.getItem("handleRepoForkList") !== null
                      ? JSON.parse(localStorage.getItem("handleRepoForkList"))
                      : [];
                  if (!containsObject(currentConfig, list)) {
                    console.log(list, containsObject(currentConfig, list));
                    list.push(currentConfig);
                    const newList = JSON.stringify(list);
                    // console.log(currentConfig, newList)
                    localStorage.setItem("handleRepoForkList", newList);
                    setLocalList(list);
                  } else {
                    toast.error("Config Already Exists");
                  }
                }
              }}
              variant="contained"
              color="secondary"
            >
              Save Filter Config
            </Button>
            <Button
              onClick={() => {
                localStorage.removeItem("filterRepoForkList");
                setFilterModel({ items: [] });
              }}
              variant="contained"
              color="secondary"
            >
              Clear All Filters
            </Button>
          </Box>
          <Box m="20px">
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGridPro
                autoHeight
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                pagination
                rowsPerPageOptions={[50, 100, 300, 500]}
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.status}`
                }
                filterModel={filterModel}
                onRowClick={(params) => {
                  setModalContent(params.row);
                  setFields({
                    comments: "",
                    company: "",
                  });
                  HandleUpdate(params.row.id);
                  console.log(params);
                  setModal(true);
                }}
                onFilterModelChange={(newFilterModel) => {
                  localStorage.setItem(
                    "filterRepoForkList",
                    JSON.stringify(newFilterModel)
                  );
                  console.log(newFilterModel);
                  setFilterModel(newFilterModel);
                }}
                //filterMode={"client"}
                // onFilterModelChange={(model) => {
                //   store.update((state) => {
                //     // state.followingsFilter_timestamp_enabled = false;
                //     //   state.followingsFilter_created_at_enabled = false;
                //     if (!timestamp_clear || !created_at_clear) {
                //       state.followingsFilter_timestamp_enabled = false;
                //       state.followingsFilter_created_at_enabled = false;
                //       state.followingsFilter_timestamp_items = [];
                //       state.followingsFilter_created_at_items = [];
                //       state.followingsFilter_timestamp_clear = true;
                //       state.followingsFilter_created_at_clear = true;
                //     } else state.followingsFilter_global_items = model.items;
                //   });
                // }}
                // filterModel={{
                //   items: [
                //     ...timestampFilterModelItems(),
                //     ...created_atFilterModelItems(),
                //     ...global_items,
                //   ],
                // }}
                onColumnVisibilityModelChange={(model) => {
                  store.update((state) => {
                    state.repoForksColumns = model;
                    setLocalStorage("repoForksColumns", model);
                  });
                }}
                columnVisibilityModel={repoForksColumns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </main>
        <Modal isVisible={modal}>
          <div className="closeIcon">
            <CloseIcon
              onClick={() => {
                setModal(false);
                // setConfirmDelete(false);
              }}
            />
          </div>
          <div
            className="modalContent"
            style={{
              display: "flex",
              gap: 6,
              alignItems: "start",
            }}
          >
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://twitter.com/${modalContent?.username}`}
              >
                <img
                  className="profile_img"
                  src={modalContent?.avatar_url}
                  alt=""
                />
              </a>
              {/* Name */}
              <div className="label">
                <span>Username:</span> {modalContent?.login}
              </div>
              <div className="label">
                <span>Fork Of:</span> {modalContent?.fork_of}
              </div>
              <div className="label">
                <span>Name:</span> {modalContent?.name}
              </div>
              <div className="label">
                <span>Id:</span> {modalContent?.id}
              </div>
              <div className="label">
                <span>Forked Repo Url:</span> {modalContent?.forked_repo_url}
              </div>
              <div className="label">
                <span>Twitter:</span> {modalContent?.twitter_username}
              </div>
              <div className="label">
                <span>Created at:</span> {modalContent?.created_at}
              </div>
              <div className="label">
                <span>Website:</span> {modalContent?.blog}
              </div>
              <div className="label">
                <span>Email:</span> {modalContent?.email}
              </div>
              <div className="label">
                <span>Watchers Count:</span> {modalContent?.watchers_count}
              </div>
              <div className="label">
                <span>Location:</span> {modalContent?.location}
              </div>
              <div className="label">
                <span>Language:</span> {modalContent?.language}
              </div>
              <div className="label">
                <span>Repos:</span> {modalContent?.public_repos}
              </div>
              <div className="label">
                <span>Gists:</span> {modalContent?.public_gists}
              </div>
              <div className="label">
                <span>Followers:</span> {modalContent?.followers}
              </div>
              <div className="label">
                <span>Following:</span> {modalContent?.following}
              </div>
              <div className="label">
                <span>Company:</span> {modalContent?.company}
              </div>
              <div className="label">
                <span>Comments:</span> {modalContent?.comments}
              </div>
            </div>
          </div>
          {/* Delete Button */}
          {/* <div className="deleteButton">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setConfirmDelete(!confirmDelete);
              }}
            >
              {confirmDelete ? "Cancel Deletion" : "Delete"}
            </Button>
          </div>
          {confirmDelete && (
            <div className="deleteButton">
              <p className="error_msg">
                This data will be deleted from database for everyone, please
                confirm your deletion!
              </p>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  deleteHandles(modalContent?.username);
                  // setModal(false);
                }}
              >
                Confirm Delete
              </Button>
            </div>
          )} */}
        </Modal>
      </div>
    );
  }
};

export default RepoForks;
