import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ContactsIcon from "@mui/icons-material/Contacts";
import GitHubIcon from "@mui/icons-material/GitHub";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import StarsIcon from "@mui/icons-material/Stars";
import {
  Filter1Outlined,
  Filter1TwoTone,
  Filter2Sharp,
  NotificationImportant,
  PersonAddAlt1,
} from "@mui/icons-material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useAuth } from "../../contexts/AuthContext";
import Logo from "./logo.png";
import "../scenes.css";
import AnalyticsIcon from "@mui/icons-material/Analytics";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const { logout } = useAuth();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  RT PORTAL
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={Logo}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                {/* <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Fname Lname
                </Typography> */}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              MONITORING
            </Typography>
            <Item
              title="Monitoring"
              to="/monitoring"
              icon={<FactCheckIcon />}
              // selected={selected}
              // setSelected={setSelected}
            />
            <Item
              title="Monitoring Analytics"
              to="/monitoring-analytics"
              icon={<AutoGraphIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              TWITTER
            </Typography>
            <Item
              title="Twitter Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              // selected={selected}
              // setSelected={setSelected}
            />
            <Item
              title="Twitter Handles"
              to="/handles"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Add Handles"
              to="/add-handles"
              icon={<PersonAddAlt1 />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Twitter Analytics"
              to="/analytics"
              icon={<AnalyticsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Tweets"
              to="/tweets"
              icon={<TwitterIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Item
              title="Notifications"
              to="/notifications"
              icon={<NotificationImportant />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              GITHUB
            </Typography>
            <Item
              title="Add User/Repo"
              to="/add-github-handles"
              icon={<ContactsIcon />}
              // selected={selected}
              // setSelected={setSelected}
            />
            <Item
              title="User List"
              to="/github-user-list"
              icon={<GitHubIcon />}
              // selected={selected}
              // setSelected={setSelected}
            />
            <Item
              title="User Stars"
              to="/github-user-stars"
              icon={<StarsIcon />}
              // selected={selected}
              // setSelected={setSelected}
            />
            <Item
              title="Repo List"
              to="/github-repo-list"
              icon={<IntegrationInstructionsIcon />}
              // selected={selected}
              // setSelected={setSelected}
            />
            <Item
              title="Repo Forks"
              to="/github-repo-forks"
              icon={<ForkRightIcon />}
              // selected={selected}
              // setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              UPDATES & SUPPORT
            </Typography>
            <Item
              title="Releases"
              to="/releases"
              icon={<NewReleasesIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Filtering Dictionary"
              to="/filtering"
              icon={<Filter2Sharp />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              PROFILE
            </Typography>
            <Item
              title="View/Edit Profile"
              to="/profile"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <MenuItem
              active={false}
              style={{
                color: colors.grey[100],
              }}
              onClick={async (e) => {
                e.preventDefault();
                logout();
              }}
              icon={<LogoutIcon />}
            >
              <Typography>Log Out</Typography>
            </MenuItem> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
