import { Box, Button, useTheme } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useCallback } from "react";
import {
  collection,
  deleteDoc,
  getDocs,
  doc,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import Avatar from "@mui/material/Avatar";
import "../scenes.css";
import BasicDateRangePicker from "../unix";
import LoadingGif from "../../assets/loading.gif";
import { store } from "../../store/store";
import {
  getCurrentTimeStamp,
  getLastSevenDayTimeStamp,
} from "../../helpers/sevenDaysTimestamp";
import { Modal } from "../../components/Modal/Modal";
import { toast } from "react-toastify";
import { setLocalStorage } from "../../helpers/localStorage";

const Followings = () => {
  const timestamp_items = store.useState(
    (s) => s.followingsFilter_timestamp_items
  );
  const timestamp_enabled = store.useState(
    (s) => s.followingsFilter_timestamp_enabled
  );
  const created_at_items = store.useState(
    (s) => s.followingsFilter_created_at_items
  );
  const created_at_enabled = store.useState(
    (s) => s.followingsFilter_created_at_enabled
  );
  const global_items = store.useState((s) => s.followingsFilter_global_items);
  const timestamp_clear = store.useState(
    (s) => s.followingsFilter_timestamp_clear
  );
  const created_at_clear = store.useState(
    (s) => s.followingsFilter_created_at_clear
  );
  const followingsColumns = store.useState((s) => s.followingsColumns);
  const upperTimestampLimit = store.useState((s) => s.upperTimestampLimit);
  const lowerTimestampLimit = store.useState((s) => s.lowerTimestampLimit);
  const rteamIds = store.useState((s) => s.rteamIds);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const [following, setFollowing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [lastVisible, setLastVisible] = useState("");
  const [toastShown, setToastShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState();
  const [modal, setModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [localList, setLocalList] = useState([]);

  useEffect(() => {
    const newList = JSON.parse(localStorage.getItem("follList"));
    setLocalList(newList);
  }, [localStorage.getItem("follList")]);

  const [filterModel, setFilterModel] = useState(
    JSON.parse(localStorage.getItem("filterFollowings")) || { items: [] }
  );

  const data = [];

  function containsObject(obj, list) {
    return list.find(
      (elem) => JSON.stringify(elem.items) === JSON.stringify(obj.items)
    );
    // return false;
  }

  useEffect(() => {
    console.log("useeffect ");
    setFirstLoad(true);
    getData();
  }, []);

  useEffect(() => {
    getData();
    console.log("useeffect calling");
  }, [upperTimestampLimit, lowerTimestampLimit]);

  const HandleUpdate = async (id) => {
    // e.preventDefault();
    if (id) {
      try {
        console.log(modalContent);
        const res = await updateDoc(doc(db, "FOLLOWINGS", id), {
          status: 1,
        })
        console.log("UPDATED STATUS", res, id);
        // toast.success(
        //   `Updated data for ${modalContent?.username} successfully!`
        // );
        await getData();
      } catch (err) {
        console.log(err);
        // toast.error(`Failed to update data for ${modalContent?.username}!`);
      }
    }
  };

  function createUnixTS(dateStr) {
    const date = new Date(dateStr);
    // console.log(date);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }

  function unixToReadable(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    if (month < 10) {
      month = "0" + month.toString();
    }
    var date = a.getDate();
    if (date < 10) {
      date = "0" + date.toString();
    }
    var hour = a.getHours();
    if (hour < 10) {
      hour = "0" + hour.toString();
    }
    var min = a.getMinutes();
    if (min < 10) {
      min = "0" + min.toString();
    }
    var sec = a.getSeconds();
    if (sec < 10) {
      sec = "0" + sec.toString();
    }
    var time =
      year + "/" + month + "/" + date + ", " + hour + ":" + min + ":" + sec;
    return time;
  }

  // Code for infinite scrolling!

  /*
    async function  getData() {


      setLoading(true);

      console.log("Function calling...");

      if (firstLoad){

        setFirstLoad(false);
        const first = query(collection(db, "FOLLOWINGS"), orderBy("username"), limit(15));
        const d = await getDocs(first);

        const last = d.docs[d.docs.length - 1];

        setLastVisible(last);

        const follow = d.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));

        console.log(lastVisible);

        for (let i = 0; i < follow.length; i++) {
          const data_object = {};
          data_object.pfp = { profile_image_url: "", username: "" };
          data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
          data_object.pfp.username = follow[i].id;
          data_object.username = follow[i].id;
          data_object.name = follow[i].data.name;
          data_object.id = follow[i].data.id;
          data_object.followers_count =
            follow[i].data.public_metrics.followers_count;
          data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
          data_object.description = follow[i].data.description;
          data_object.created_at = createUnixTS(follow[i].data.created_at);
          data_object.created_at_utc = unixToReadable(
            createUnixTS(follow[i].data.created_at)
          );
          data_object.timestamp = follow[i].data.timestamp;
          data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
          data.push(data_object);
        }
        setIsLoading(false);
        setLoading(false);
        setFollowing(following.concat(data));



      }

      else{

        const first = query(collection(db, "FOLLOWINGS"), orderBy("username"), startAfter(lastVisible), limit(15));
        const d = await getDocs(first);

        const last = d.docs[d.docs.length - 1];
        setLastVisible(last);

        const follow = d.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));

        console.log(lastVisible);

        for (let i = 0; i < follow.length; i++) {
          const data_object = {};
          data_object.pfp = { profile_image_url: "", username: "" };
          data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
          data_object.pfp.username = follow[i].id;
          data_object.username = follow[i].id;
          data_object.name = follow[i].data.name;
          data_object.id = follow[i].data.id;
          data_object.followers_count =
            follow[i].data.public_metrics.followers_count;
          data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
          data_object.description = follow[i].data.description;
          data_object.created_at = createUnixTS(follow[i].data.created_at);
          data_object.created_at_utc = unixToReadable(
            createUnixTS(follow[i].data.created_at)
          );
          data_object.timestamp = follow[i].data.timestamp;
          data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
          data.push(data_object);
        }
        setIsLoading(false);
        setLoading(false);
        setFollowing(following.concat(data));




      }

    }

    const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = (params) => {

        console.log("Screen End");
        getData();

    };

    */

  // code for background loading!

  async function getData() {
    setLoading(true);

    if (lastVisible === "") {
      setToastShown(false);
      console.log("Inside if", lowerTimestampLimit);
      const first = query(
        collection(db, "FOLLOWINGS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        where("added_by", "in", rteamIds),
        startAfter(lastVisible),
        limit(2000)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];
      setLastVisible(last);
      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "" };
        data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
        data_object.pfp.username = follow[i].id;
        data_object.username = follow[i].id;
        data_object.name = follow[i].data.name;
        data_object.id = follow[i].data.id;
        data_object.status = follow[i].data.status;
        data_object.following_of = follow[i].data.following_of;
        data_object.followers_count =
          follow[i].data.public_metrics.followers_count;
        data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
        data_object.description = follow[i].data.description;
        data_object.created_at = createUnixTS(follow[i].data.created_at);
        data_object.created_at_utc = unixToReadable(
          createUnixTS(follow[i].data.created_at)
        );
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    } else {
      console.log("Inside else");

      if (!lastVisible && !toastShown) {
        toast.success("Data loaded successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToastShown(true);
        return;
      }

      const first = query(
        collection(db, "FOLLOWINGS"),
        orderBy("timestamp", "desc"),
        where("timestamp", ">", lowerTimestampLimit),
        where("timestamp", "<", upperTimestampLimit),
        where("added_by", "in", rteamIds),
        // startAfter(lastVisible),
        limit(2000)
      );

      const d = await getDocs(first);

      const last = d.docs[d.docs.length - 1];

      setLastVisible(last);

      const follow = d.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));

      for (let i = 0; i < follow.length; i++) {
        const data_object = {};
        data_object.pfp = { profile_image_url: "", username: "" };
        data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
        data_object.pfp.username = follow[i].id;
        data_object.username = follow[i].id;
        data_object.name = follow[i].data.name;
        data_object.id = follow[i].data.id;
        data_object.status = follow[i].data.status;
        data_object.following_of = follow[i].data.following_of;
        data_object.followers_count =
          follow[i].data.public_metrics.followers_count;
        data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
        data_object.description = follow[i].data.description;
        data_object.created_at = createUnixTS(follow[i].data.created_at);
        data_object.created_at_utc = unixToReadable(
          createUnixTS(follow[i].data.created_at)
        );
        data_object.timestamp = follow[i].data.timestamp;
        data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
        data.push(data_object);
      }

      if (follow.length === 0) {
        setLoading(true);
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      } else {
        setFollowing(following.concat(data));
        setIsLoading(false);
        setLoading(false);
      }
    }
  }

  console.log(following, "FOLLOWINGS");
  const deleteFollowing = async (username) => {
    const response = await deleteDoc(doc(db, "FOLLOWINGS", username));
    if (response === undefined) {
      setFollowing(following.filter((item) => item.username !== username));
      toast.success(`Deleted ${username} successfully!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setModal(false);
      return;
    }
    toast.error(`Error deleting ${username}!`);
  };

  // Code for simple loading!

  function getFollowings() {
    const followingCollectionRef = collection(db, "FOLLOWINGS");
    getDocs(followingCollectionRef)
      .then((response) => {
        const follow = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        // console.log(follow);
        for (let i = 0; i < follow.length; i++) {
          const data_object = {};
          data_object.pfp = { profile_image_url: "", username: "" };
          data_object.pfp.profile_image_url = follow[i].data.profile_image_url;
          data_object.pfp.username = follow[i].id;
          data_object.username = follow[i].id;
          data_object.name = follow[i].data.name;
          data_object.id = follow[i].data.id;
          data_object.status = follow[i].data.status;
          data_object.followers_count =
            follow[i].data.public_metrics.followers_count;
          data_object.tweet_count = follow[i].data.public_metrics.tweet_count;
          data_object.description = follow[i].data.description;
          data_object.created_at = createUnixTS(follow[i].data.created_at);
          data_object.created_at_utc = unixToReadable(
            createUnixTS(follow[i].data.created_at)
          );
          data_object.timestamp = follow[i].data.timestamp;
          data_object.timestamp_utc = unixToReadable(follow[i].data.timestamp);
          data.push(data_object);
        }
        setIsLoading(false);
        setFollowing(data);
      })
      .catch((error) => console.log(error.message));
  }

  const columns = [
    {
      field: "pfp",
      headerName: "PFP",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <a
              className="twitter_url"
              onClick={() => {
                setModal(false);
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/${params.value.username}`}
            >
              <Avatar src={params.value.profile_image_url} />
            </a>
          </>
        );
      },
    },
    {
      field: "username",
      headerName: "Username",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },
    {
      field: "following_of",
      headerName: "Following Of",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },

    {
      field: "id",
      headerName: "Twitter ID",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
    },
    {
      field: "followers_count",
      headerName: "Followers",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "tweet_count",
      headerName: "Tweets",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 4,
    },
    {
      field: "created_at_utc",
      headerName: "Creation (yyyy/mm/dd)",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "timestamp_utc",
      headerName: "Timestamp (yyyy/mm/dd)",
      flex: 0.7,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "created_at",
      headerName: "Creation (UNIX)",
      flex: 0.3,
      type: "number",
      headerAlign: "left",
      align: "left",
    },

    {
      field: "timestamp",
      headerName: "Timestamp (UNIX)",
      flex: 0.3,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
  ];

  const timestampFilterModelItems = () => {
    return timestamp_enabled ? timestamp_items : [];
  };

  const created_atFilterModelItems = () => {
    if (created_at_enabled) {
      console.log(created_at_items);
    }
    return created_at_enabled ? created_at_items : [];
  };

  if (isLoading) {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Header
              title="FOLLOWINGS OF HANDLES"
              subtitle="List of all followings of Handles for possible leads."
            />
          </Box>

          <img className="loading_image" src={LoadingGif} alt="loading" />
          <p className="loading_text">Please be Patient! Fetching all data!</p>
        </main>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px" className="container_flex">
            <Header
              className="header_flex"
              title="FOLLOWINGS OF HANDLES"
              subtitle="List of all followings of Handles for possible leads."
            />
            <BasicDateRangePicker
              followingCallback={setFollowing}
              lastVisibleCallback={setLastVisible}
              unix={"timestamp"}
              className="date_flex"
            />
            <BasicDateRangePicker unix={"created_at"} className="date_flex" />
          </Box>
          <Box m="20px" className="container_flex" gap={2}>
            {localList?.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Button
                  onClick={() => {
                    localStorage.setItem(
                      "filterFollowings",
                      JSON.stringify(item)
                    );
                    setFilterModel(item);
                  }}
                  variant="outlined"
                  color="info"
                >
                  Config {index + 1}
                </Button>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const newList = localList.filter(
                      (listt) => JSON.stringify(listt) !== JSON.stringify(item)
                    );
                    localStorage.setItem("follList", JSON.stringify(newList));
                    setLocalList(newList);
                  }}
                />
              </div>
            ))}
          </Box>
          <Box
            m="20px"
            className="container_flex"
            justifyContent={"space-between"}
          >
            <Button
              onClick={() => {
                const currentConfig = JSON.parse(
                  localStorage.getItem("filterFollowings")
                );
                if (currentConfig?.items?.length > 0) {
                  const list =
                    localStorage.getItem("follList") !== null
                      ? JSON.parse(localStorage.getItem("follList"))
                      : [];
                  if (!containsObject(currentConfig, list)) {
                    console.log(list, containsObject(currentConfig, list));
                    list.push(currentConfig);
                    const newList = JSON.stringify(list);
                    // console.log(currentConfig, newList)
                    localStorage.setItem("follList", newList);
                    setLocalList(list);
                  } else {
                    toast.error("Config Already Exists");
                  }
                }
              }}
              variant="contained"
              color="secondary"
            >
              Save Filter Config
            </Button>
            <Button
              onClick={() => {
                localStorage.removeItem("filterFollowings");
                setFilterModel({ items: [] });
              }}
              variant="contained"
              color="secondary"
            >
              Clear All Filters
            </Button>
          </Box>
          <Box m="20px">
            <Box
              m="0 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGridPro
                autoHeight
                rows={following}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.status}`
                }
                // on row click set the row details in the state in the setModalContent
                onRowClick={(params) => {
                  HandleUpdate(params.row.username);
                  setModalContent(params.row);
                  console.log(params);
                  setModal(true);
                }}
                // loading={loading}
                // onRowsScrollEnd={handleOnRowsScrollEnd}
                // hideFooterPagination
                pagination
                rowsPerPageOptions={[50, 100, 300, 500]}
                // filterMode={filterModel}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {
                  localStorage.setItem(
                    "filterFollowings",
                    JSON.stringify(newFilterModel)
                  );
                  console.log(newFilterModel);
                  setFilterModel(newFilterModel);
                }}
                // onFilterModelChange={(model) => {
                //   setFilterModel(model)
                //   // store.update((state) => {
                //   //   // state.followingsFilter_timestamp_enabled = false;
                //   //   //   state.followingsFilter_created_at_enabled = false;
                //   //   if (!timestamp_clear || !created_at_clear) {
                //   //     state.followingsFilter_timestamp_enabled = false;
                //   //     state.followingsFilter_created_at_enabled = false;
                //   //     state.followingsFilter_timestamp_items = [];
                //   //     state.followingsFilter_created_at_items = [];
                //   //     state.followingsFilter_timestamp_clear = true;
                //   //     state.followingsFilter_created_at_clear = true;
                //   //   } else state.followingsFilter_global_items = model.items;
                //   // });
                // }}
                // filterModel={{
                //   items: [
                //     ...timestampFilterModelItems(),
                //     ...created_atFilterModelItems(),
                //     ...global_items,
                //   ],
                // }}
                onColumnVisibilityModelChange={(model) => {
                  store.update((state) => {
                    state.followingsColumns = model;
                    setLocalStorage("followingsColumns", model);
                  });
                }}
                columnVisibilityModel={followingsColumns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Box>
        </main>
        <Modal isVisible={modal}>
          <div className="closeIcon">
            <CloseIcon
              onClick={() => {
                setModal(false);
                setConfirmDelete(false);
              }}
            />
          </div>
          <div className="modalContent">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/${modalContent?.username}`}
            >
              <img
                className="profile_img"
                src={modalContent?.pfp?.profile_image_url}
                alt=""
              />
            </a>
            {/* Name */}
            <div className="label">
              <span>Name:</span> {modalContent?.name}
            </div>
            <div className="label">
              <span>Username:</span> {modalContent?.username}
            </div>
            <div className="label">
              <span>Description:</span> {modalContent?.description}
            </div>
            <div className="label">
              <span>Created at:</span> {modalContent?.created_at_utc}
            </div>
            <div className="label">
              <span>Folllowers count:</span> {modalContent?.followers_count}
            </div>
            <div className="label">
              <span>Following of:</span> {modalContent?.following_of}
            </div>
            <div className="label">
              <span>Tweet count:</span> {modalContent?.tweet_count}
            </div>
            <div className="label">
              <span>Id:</span> {modalContent?.id}
            </div>
          </div>
          {/* Delete Button */}
          {/* Delete Button */}
          <div className="deleteButton">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setConfirmDelete(!confirmDelete);
              }}
            >
              {confirmDelete ? "Cancel Deletion" : "Delete"}
            </Button>
          </div>
          {confirmDelete && (
            <div className="deleteButton">
              <p className="error_msg">
                This data will be deleted from database for everyone, please
                confirm your deletion!
              </p>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  deleteFollowing(modalContent?.username);
                  // setModal(false);
                }}
              >
                Confirm Delete
              </Button>
            </div>
          )}
        </Modal>
      </div>
    );
  }
};

export default Followings;
